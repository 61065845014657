import React, { useState } from 'react'
import PlusIcon from '../../assets/plus.svg';
import MinusIcon from '../../assets/minus.svg';
import ArrowDownIcon from '../../assets/arrowDown.svg'
import ArrowUpIcon from '../../assets/arrowUp.svg'



export default function Help() {
    const [activeQuestion, setActiveQuestion] = useState('')
    const [activeChildren, setActiveChildren] = useState('')
    const questionsList = [
        {
            name: 'Frequently Asked Questions',
            key: 'faq'
        },
        {
            name: 'Contacts',
            key: 'contacts'
        }
    ]

    const childrensData = [
        {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            answer: "1Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text  ever unknown printer took a galley of type and scrambled it to make a type specimen book.",
            key: 'mentee'
        },
        {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            answer: "2Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text  ever unknown printer took a galley of type and scrambled it to make a type specimen book.",
            key: 'bridge'
        },
    ]

    const contactChildrensData = [
        {
            text1: 'Still have any query?',
            text2: " Please contact us",
            key: 'query'
        },
        {
            text1: ' Is your old query unresolved? ',
            text2: " Please contact officer",
            key: 'officer'
        },
    ]


    const handleOpen = (key) => {
        let tab = key
        if (activeQuestion === key) { tab = '' }
        setActiveQuestion(tab)
        setActiveChildren('')
    }

    const handleChildrenData = (key) => {
        let list = key
        if (activeChildren === key) { list = '' }
        setActiveChildren(list)

    }


    return (
        <div className="feedback px-9 py-9">

            <div className='px-3 py-5 h-[750px]' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '10px' }}>

                <div style={{ border: '1px solid rgba(34, 96, 217, 1)', borderRadius: '6px' }} className='my-4 mx-3 px-5 py-4'>
                    <div className='flex justify-between px-5 pb-4 mb-4 items-center' style={{ borderBottom: '1px solid rgba(217, 228, 242, 1)' }}>
                        <div className='flex w-full gap-5 items-center justify-between'>
                            <p style={{ color: 'rgba(24, 40, 61, 1)', fontWeight: 700 }}>Help Desk</p>
                        </div>
                    </div>

                    <div className='help-desk-details px-4'>
                        <div className='font-semibold text-[16px]' style={{ color: 'rgba(29, 91, 191, 1)' }}>Help Desk Overview</div>

                        <div className='text-[12px] py-4 leading-6'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                        </div>


                        <div className='help-question-list'>
                            {
                                questionsList.map((questionList, index) =>
                                    <div style={{ borderBottom: questionsList.length === (parseInt(index) + 1) ? 'none' : '1px solid rgba(198, 198, 198, 1)' }}>
                                        <div key={index} className='flex w-[80%] justify-between py-3 items-center' >
                                            <div className='text-[20px] font-semibold'>
                                                {index + 1} . {questionList.name}
                                            </div>
                                            <div className='cursor-pointer' onClick={() => handleOpen(questionList.key)}>
                                                <img src={questionList.key === activeQuestion ? MinusIcon : PlusIcon} alt={'Icon'} />
                                            </div>

                                        </div>
                                        {
                                            questionList.key === activeQuestion &&

                                            <div className='w-[80%]'>
                                                <div className='toggle-data-list'>
                                                    {
                                                        activeQuestion === 'contacts' &&


                                                        <div className='toggle-data' style={{ borderBottom:  'none'}}>
                                                            <div className='flex justify-between items-center pt-4 pb-2'>
                                                                <div className='child-question'>
                                                                    Still have any query
                                                                    <span className='cursor-pointer underline text-primary-red pl-2'>Please contact us</span>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    }
                                                    {

                                                        activeQuestion === 'faq' &&
                                                        childrensData.map((childrenData, index) =>
                                                            <div className='toggle-data' key={index} style={{ borderBottom: childrensData.length === (parseInt(index) + 1) ? 'none' : '1px solid rgba(188, 188, 188, 1)' }}>
                                                                <div className='flex justify-between items-center pt-4 pb-2'>
                                                                    <div className='child-question'>{childrenData.question}</div>
                                                                    <img className='w-[20px] cursor-pointer' src={childrenData.key !== activeChildren ? ArrowDownIcon : ArrowUpIcon} alt="Icon" onClick={() => handleChildrenData(childrenData.key)} />
                                                                </div>
                                                                {
                                                                    childrenData.key === activeChildren &&

                                                                    <div className='text-[12px] child-answer pb-3'>
                                                                        {childrenData.answer}
                                                                    </div>
                                                                }

                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}