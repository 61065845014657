import {
  configureStore,
  combineReducers
} from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import categoryReducer from "../features/category/categorySlice";
import requestReducer from "../features/request/requestSlice";
import parnerReducer from "../features/partner/partnerSlice";



const combinedReducer = combineReducers({
  userInfo: userReducer,
  categoryInfo: categoryReducer,
  requestInfo: requestReducer,
  partnerInfo: parnerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer

});