function Table({ rows, tableName }) {
  return (
    <table className="bg-[#E4EDFF] my-8 border border-[#E4EDFF]">
      <tr>
        <th className="py-4 px-6 text-[#2260D9]" colSpan={2} align="left">
          {tableName}
        </th>
      </tr>
      {rows.map((row, index) => (
        <tr className="bg-white border-b border-b-[#E4EDFF]" key={index}>
          <td className="w-1/2 py-4 px-6 border-r border-r-[#E4EDFF] text-[#353F4F]">
            {row.label}
          </td>
          <td className="w-1/2 py-4 px-6 text-[#353F4F]">{row.value}</td>
        </tr>
      ))}
    </table>
  );
}

export default Table;
