import React, { useState } from 'react'

import SearchIcon from '../../../assets/input-search-icon.svg'
import { RequestColumn } from '../../../utills/tableFields';
import MoreIcon from '../../../assets/more-icon.svg'
import DataTable from '../../../shared/DataGrid';

export default function AgentRequest() {
  const [actionTab, setActiveTab] = useState('all');

  const actionTabFilter = [{
    key: 'all',
    name: 'All'
  },
  {
    key: 'new',
    name: 'New'
  },
  {
    key: 'in-progress',
    name: 'In Progress'
  },
  {
    key: 'completed',
    name: 'Completed'
  },
  {
    key: 'on-hold',
    name: 'On Hold'
  },
  {
    key: 'rejected',
    name: 'Rejected'
  }
  ]

  const MyRequestColumns = [
    ...RequestColumn,
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      id: 5,
      renderCell: (params) => {
        return <>
          <div className='cursor-pointer flex items-center h-full relative'>
          </div>
        </>
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      id: 4,
      renderCell: (params) => {
        return <>
          <div className='cursor-pointer flex items-center h-full' onClick={(e) => undefined}>
            <img src={MoreIcon} alt='MoreIcon' />
          </div>
        </>
      }
    },
  ]

  return (
    <>
      <div className='flex justify-between pb-4'>
        <div>Request</div>
      </div>

      <div className='px-3 py-5 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
        <div className='details-info'>
          <div className='flex justify-between px-5 mb-8 items-center ' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
            <ul className='tab-list'>
              {
                actionTabFilter.map((discussion, index) =>
                  <li className={`${actionTab === discussion.key ? 'active' : ''} relative`} key={index}
                    onClick={() => setActiveTab(discussion.key)}
                  >
                    <div className='flex justify-center pb-1'>
                      <div className={`total-proram-count relative ${actionTab === discussion.key ? 'active' : ''}`}>10
                      </div>
                    </div>
                    <div className='text-[13px]'> {`${discussion.name}`}</div>
                    {actionTab === discussion.key && <span></span>}
                  </li>)
              }
            </ul>

            <div className="relative">
              <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                placeholder="Search here..." style={{
                  border: '1px solid rgba(34, 96, 217, 1)',
                  height: '50px',
                  width: '390px'
                }} />
              <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                <img src={SearchIcon} alt='SearchIcon' />
              </div>
            </div>
          </div>
        </div>
        <div className='px-3'>
          <DataTable rows={[]} columns={MyRequestColumns} hideCheckbox />
        </div>
      </div>

    </>
  )
}
