import React from 'react'
import { useNavigate } from 'react-router'

import donationIcon from '../../assets/donation-icon.svg'
import mentorIcon from '../../assets/mentor-icon.svg'
import aidIcon from '../../assets/aid-icon.svg'
import advocacyIcon from '../../assets/advocacy-icon.svg'
import housingIcon from '../../assets/housing-repair-icon.svg'
import childcareIcon from '../../assets/childcare-icon.svg'
import counselingIcon from '../../assets/counseling-icon.svg'


export default function FrequentCategory() {
    const navigate = useNavigate()
    const frequentCategory = [
        {
            name: 'Donations Request',
            key: 'donation',
            img: donationIcon
        },
        {
            name: 'Mentorship Request',
            key: 'mentor',
            img: mentorIcon
        },
        {
            name: 'AID Request',
            key: 'aid',
            img: aidIcon
        },
        {
            name: 'Advocacy Request',
            key: 'advocacy',
            img: advocacyIcon
        },
        {
            name: 'Housing Repair',
            key: 'housing',
            img: housingIcon
        },
        {
            name: 'Childcare Support',
            key: 'childcare',
            img: childcareIcon
        },
        {
            name: 'Counseling Request',
            key: 'counseling',
            img: counselingIcon
        },
    ]
    return (
        <div style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.05)', borderRadius: '10px' }} className='mt-5'>
            <div className="title flex justify-between py-3 px-4 items-center" style={{ borderBottom: '1px solid #2260D9' }}>
                <div className="flex gap-4">
                    <h4 className='text-[20px]'>Frequently Used Categories</h4>
                </div>
            </div>
            <div className='flex px-4 gap-10 py-7'>
                {
                    frequentCategory.map((freqCat) => {
                        return (
                            <div style={{
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                padding: '20px 42px 30px', borderRadius: '5px', width: '170px',
                                textAlign: 'center'
                            }} className='flex flex-col gap-4 cursor-pointer' key={freqCat.key} onClick={() => navigate('/category/1')}>
                                <img src={freqCat.img} className='h-9' alt="DonationIcon" />
                                <p>{freqCat.name}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}
