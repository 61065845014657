import React, { useState } from 'react'
import { CategoryColumn } from '../../utills/tableFields'
import DataTable from '../../shared/DataGrid'

import MoreIcon from '../../assets/more-icon.svg'

export default function AgentCategory() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState({})

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(data)
    };

    const CategoriesColumn = [
        ...CategoryColumn,
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            id: 4,
            renderCell: (params) => {
                return <>
                    <div className='cursor-pointer flex items-center h-full' onClick={(e) => handleClick(e, params.row)}>
                        <img src={MoreIcon} alt='MoreIcon' />
                    </div>
                </>
            }
        },
    ]
    return (
        <DataTable rows={[]} columns={CategoriesColumn} hideCheckbox />
    )
}
