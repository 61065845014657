export const PrimaryInfoFields = [{
        type: "input",
        fieldType: 'text',
        name: "first_name",
        label: "First Name",
        placeholder: "Enter your first name",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-[49%]'
    },
    {
        type: "input",
        fieldType: 'text',
        name: "last_name",
        label: "Last Name",
        placeholder: "Enter your last name",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-[49%]'
    },
    {
        type: "input",
        fieldType: 'number',
        name: "primary_contact_number",
        label: "Primary Contact Number",
        placeholder: "Enter Primary Contact Number",
        inputRules: {
            required: "This field is required",
            pattern: {
                value: /^[0-9]{10}$/,
                message: "Invalid Phone number",
            },
        },
        width: 'w-[49%]'
    },
    {
        type: "input",
        fieldType: 'number',
        name: "secondary_contact_number",
        label: "Secondary Contact Number",
        placeholder: "Enter Secondary Contact Number",
        inputRules: {

        },
        width: 'w-[49%]'
    },
    {
        type: "input",
        fieldType: 'text',
        name: "address",
        label: "Address",
        placeholder: "Enter your Address",
        inputRules: {
            required: "This field is required   ",
        },
        width: 'w-full'
    }
];

export const RequestDetailsFields = [{
        type: "dropdown",
        fieldType: 'select',
        name: "category",
        label: "Request Category",
        placeholder: "Select request Category",
        inputRules: {
            required: "This field is required",
        },
        options: [{
                name: 'Category1',
                value: 'category1'
            },
            {
                name: 'Category 2',
                value: 'category2'
            },
            {
                name: 'Category3',
                value: 'category3'
            }
        ],
        width: 'w-[49%]'
    },
    {
        type: "input",
        fieldType: 'text',
        name: "beneficiary",
        label: "Number of Beneficiaries",
        placeholder: "Enter beneficiaries",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-[49%]'
    },
    {
        type: "radio",
        fieldType: 'radio',
        name: "urgency_level",
        label: "Urgency Level",
        placeholder: "Enter Urgency Level",
        inputRules: {
            required: "This field is required",
        },
        options: [{
                name: 'High',
                value: 'high'
            },
            {
                name: 'Medium',
                value: 'medium'
            },
            {
                name: 'Low',
                value: 'low'
            }
        ],
        width: 'w-full'
    },
    {
        type: "textbox",
        fieldType: 'text',
        name: "desc",
        label: "Description",
        placeholder: "Enter Description",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-full'
    },
    {
        type: "file",
        fieldType: 'file',
        name: "attachment",
        label: "Attachments (optional)",
        placeholder: "Upload attachment",
        width: 'w-full'
    }

];

export const ProfileFields = [{
        type: "input",
        fieldType: 'text',
        name: "name",
        label: "Name",
        placeholder: "Enter Your Name",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-full'
    },
    {
        type: "input",
        fieldType: 'text',
        name: "phone_number",
        label: "Phone Number",
        placeholder: "Enter Your phone number",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-full'
    },
    {
        type: "input",
        fieldType: 'email',
        name: "email",
        label: "Email Id",
        placeholder: "Enter Your Email Id",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-full'
    },
    {
        type: "input",
        fieldType: 'text',
        name: "address",
        label: "Address",
        placeholder: "Enter Your Address",
        inputRules: {
            required: "This field is required",
        },
        width: 'w-full'
    }
]


export const AllRequestTab = [{
        name: 'All',
        key: 'all',
    },
    {
        name: 'Completed',
        key: 'completed',
    },
    {
        name: 'On Hold',
        key: 'on-hold',
    },
    {
        name: 'Rejected',
        key: 'rejected',
    }
]

export const PartnersTab = [{
        name: 'All',
        key: 'all',
    },
    {
        name: 'Active',
        key: 'active',
    },
    {
        name: 'Inactive',
        key: 'inactive',
    }
]