import {
    createAsyncThunk,
} from "@reduxjs/toolkit";

import api from "./api";


export const getAllCategoryList = createAsyncThunk(
    "getAllCategoryList",
    async () => {
        const getAllCategoryInfo = await api.get('/agent/category-list');
        if (getAllCategoryInfo.status === 200 && getAllCategoryInfo.data) {
            return getAllCategoryInfo.data
        }
        return getAllCategoryInfo
    }
);

// Get All Category
export const getAllCategory = createAsyncThunk(
    "getAllCategory",
    async () => {
        const listAllCategoryList = await api.get('/agent/category/');
        console.log('listAllCategoryList', listAllCategoryList)
        if (listAllCategoryList.status === 200 && listAllCategoryList.data.result) {
            return listAllCategoryList.data.result
        }
        return listAllCategoryList
    }
);

// Get Category by Id
export const getCategoryById = createAsyncThunk(
    "getCategoryById",
    async (id) => {
        const getCategoryDetails = await api.get(`/agent/category/?category_id=${id}`);
        console.log('getCategoryDetails', getCategoryDetails)
        if (getCategoryDetails.status === 200 && getCategoryDetails.data.result) {
            return getCategoryDetails.data.result
        }
        return getCategoryDetails
    }
);


