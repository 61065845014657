import { useState } from "react";
import Switch from "../../shared/Switch";

function Cards() {
  const [isAvailable, setIsAvailable] = useState(true);

  return (
    <div className="flex flex-wrap gap-11">
      <div className="text-primary-blue p-11 bg-secondary-blue rounded shadow-md">
        <h2 className="font-semibold text-[35px]">Good Morning, John</h2>
        <span>Nonprofit Organization</span>
      </div>
      <div className="px-1 rounded shadow-md flex flex-col">
        <div className="p-5 flex flex-grow gap-8">
          <p className="font-medium text-lg text-wrap">
            Total
            <br />
            Request
          </p>
          <div className="px-5 h-[70px] flex items-center justify-center rounded bg-dark-gray text-white text-[38px]">
            87
          </div>
        </div>
        <div className="border-t-2 border-t-gray-bg"></div>
        <div className="text-xs px-5 py-3">
          <span>Last Update: </span>
          <span className="text-primary-blue">John Doe</span>
        </div>
      </div>
      <div className="px-1 rounded shadow-md flex flex-col bg-secondary-blue">
        <div className="p-5 flex flex-grow gap-8">
          <p className="font-medium text-lg text-wrap">
            New
            <br />
            Request
          </p>
          <div className="px-5 h-[70px] flex items-center justify-center rounded bg-primary-blue text-white text-[38px]">
            87
          </div>
        </div>
        <div className="border-t-2 border-t-gray-bg"></div>
        <div className="text-xs px-5 py-3">
          <span>Last Update: </span>
          <span className="text-primary-blue">John Doe</span>
        </div>
      </div>
      <div className="px-1 rounded shadow-md flex flex-col bg-secondary-green">
        <div className="p-5 flex flex-grow gap-8">
          <p className="font-medium text-lg text-wrap">
            Completed
            <br />
            Request
          </p>
          <div className="px-5 h-[70px] flex items-center justify-center rounded bg-primary-green text-white text-[38px]">
            87
          </div>
        </div>
        <div className="border-t-2 border-t-gray-bg"></div>
        <div className="text-xs px-5 py-3">
          <span>Last Update: </span>
          <span className="text-primary-blue">John Doe</span>
        </div>
      </div>
      <div className="p-8 rounded shadow-md flex gap-3 items-center">
        <div>
          <h2 className="font-medium text-3xl">Yes, I'm Available</h2>
          <p>Choose the option you want as your default setting.</p>
        </div>
        <span className="w-1/5">
          <Switch value={isAvailable} onChange={setIsAvailable} />
        </span>
      </div>
    </div>
  );
}

export default Cards;
