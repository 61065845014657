import { Chip } from "@mui/material";
import aidIcon from "../../assets/aid-icon.svg";
import chatIcon from "../../assets/chat.svg";
import { Link } from "react-router-dom";
import Table from "./Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function PartnerRequestDetailView() {
  return (
    <main className="p-10 bg-[#FCFCFC]">
      <p>Request</p>
      <div className="flex flex-col gap-2 mt-7 p-10 border border-light-gray rounded">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-6">
            <img
              src={aidIcon}
              style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
              className="shadow-2xl p-2 rounded"
            />
            <h2 className="text-4xl font-semibold">AID Request</h2>
            <span className="px-8 py-2 border border-[#FFCD11] text-[#FFCD11] bg-[#ffcb1110] rounded-3xl">
              In Progress
            </span>
          </div>
          <div className="flex gap-4">
            <button className="border border-light-gray p-2 rounded">
              <img src={chatIcon} />
            </button>
            <button className="bg-primary-blue text-white px-4 rounded-sm flex items-center gap-8">
              Accepted
              <KeyboardArrowDownIcon className="text-white" />
            </button>
          </div>
        </div>
        <div className="flex gap-4 ml-[92px]">
          <div className="flex gap-2">
            <span>Request ID: </span>
            <span className="text-primary-blue underline">#1234567890</span>
          </div>
          <div className="h-6 border-r-2 border-r-light-gray"></div>
          <div className="flex gap-2">
            <span>Request Date: </span>
            <span className="text-primary-blue underline">12/12/2024</span>
          </div>
          <div className="h-6 border-r-2 border-r-light-gray"></div>
          <div className="flex gap-2">
            <span>Accepted Date: </span>
            <span className="text-primary-blue underline">12/12/2024</span>
          </div>
        </div>
        <Link
          to="/partner/requests/requestId/tracking"
          className="ml-[92px] underline text-primary-blue"
        >
          View Tracking Page
        </Link>

        <Table
          tableName="Requester Details"
          rows={[
            { label: "First Name", value: "John" },
            { label: "Last Name", value: "Doe" },
            { label: "Primary Contact Number", value: "0123456789" },
            { label: "Secondary Contact Number", value: "0123456789" },
            { label: "Address", value: "ABC, Fanhattans, NY-600049" },
          ]}
        />

        <Table
          tableName="Request Details"
          rows={[
            { label: "Request Via", value: "Call" },
            { label: "Request Category", value: "Food And Nutrition's Aid" },
            { label: "Number of Beneficiaries", value: "05" },
            { label: "Urgency Level", value: "Low" },
            {
              label: "Description",
              value:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
            },
          ]}
        />

        <Table
          tableName="Agent Details"
          rows={[
            { label: "Name", value: "Company Name" },
            { label: "Contact", value: "0123456789" },
            { label: "Email Id", value: "abc@gmail.com" },
            { label: "Address", value: "ABC, Fanhattans, NY-600049" },
          ]}
        />

        <Table
          tableName="Consumer other Details"
          rows={[
            { label: "Identification Proff", value: "Image Attached" },
            { label: "Communication Intake", value: "Opt-in" },
          ]}
        />

        <Table
          tableName="Accept Comment"
          rows={[
            {
              label: "Description",
              value:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
            },
          ]}
        />

        <Table
          tableName="Completed Comment"
          rows={[
            {
              label: "Description",
              value:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
            },
          ]}
        />

        <button className="border border-primary-blue text-primary-blue rounded px-12 py-2 w-max self-center">
          Back
        </button>
      </div>
    </main>
  );
}

export default PartnerRequestDetailView;
