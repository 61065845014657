import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import Dashboard from "./components/Dashboard";
import CreateRequest from "./components/Request/CreateRequest";
import Category from "./components/Category";
import AllRequest from "./components/Request/AllRequest";
import Notification from "./components/Notification";
import Help from "./components/Help";
import ViewRequest from "./components/Request/View";
import ViewCategory from "./components/Category/ViewCategory";
import Partner from "./components/Partner";
import ViewPartner from "./components/Partner/ViewPartner";
import Profile from "./components/Profile";
import PartnerDashboard from "./components/PartnerDashboard";
import PartnerRequestsView from "./components/PartnerRequestsView";
import PartnerRequestDetailView from "./components/PartnerRequestDetailView";
import PartnerTrackingView from "./components/PartnerTrackingView";
import Logout from "./components/Logout";

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/help" element={<Help />} />
        <Route path="/category" element={<Category />} />
        <Route path="/category/:id" element={<ViewCategory />} />
        <Route path="/create-request" element={<CreateRequest />} />
        <Route path="/all-request" element={<AllRequest />} />
        <Route path="/view-request/:id" element={<ViewRequest />} />

        <Route path="/profile" element={<Profile />} />
        <Route path="/partners" element={<Partner />} />
        <Route path="/partner/:id" element={<ViewPartner />} />

        <Route path="/partner">
          <Route path="dashboard" element={<PartnerDashboard />} />
          <Route path="requests" element={<PartnerRequestsView />} />
          <Route
            path="requests/:requestId"
            element={<PartnerRequestDetailView />}
          />
          <Route
            path="requests/:requestId/tracking"
            element={<PartnerTrackingView />}
          />
        </Route>

      </Route>
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
