import SearchIcon from "../../assets/search-icon.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "important",
    headerName: "",
    maxWidth: 40,
    renderCell: () => (
      <StarIcon fontSize="medium" className="text-primary-orange" />
    ),
  },
  { field: "requestId", minWidth: 100, headerName: "Request ID" },
  { field: "consumerName", minWidth: 150, headerName: "Consumer Name" },
  { field: "contact", minWidth: 100, headerName: "Contact" },
  { field: "description", minWidth: 150, headerName: "Description" },
  { field: "category", minWidth: 80, headerName: "Category" },
  { field: "via", headerName: "Via" },
  { field: "priorityLevel", headerName: "Level" },
  { field: "intakeDate", minWidth: 125, headerName: "Intake Date" },
  { field: "requestedBy", minWidth: 125, headerName: "Requested by" },
  { field: "receivedDate", minWidth: 125, headerName: "Received date" },
  { field: "yourResponse", minWidth: 125, headerName: "Your Response" },
  { field: "requestStatus", minWidth: 125, headerName: "Request Status" },
  {
    headerName: "Action",
    renderCell: () => <MoreVertIcon fontSize="medium" />,
  },
];

const data = [
  {
    id: 1,
    requestId: "#1234567890",
    consumerName: "Consumer Name 1",
    contact: "9876543210",
    description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
    category: "Food Relief",
    via: "call",
    priorityLevel: "high",
    intakeDate: new Date().toISOString(),
    requestedBy: "Agent",
    receivedDate: new Date().toISOString(),
    yourResponse: "waiting",
    requestStatus: "new",
  },
  {
    id: 2,
    requestId: "#1234567890",
    consumerName: "Consumer Name 1",
    contact: "9876543210",
    description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
    category: "Food Relief",
    via: "call",
    priorityLevel: "high",
    intakeDate: new Date().toISOString(),
    requestedBy: "Agent",
    receivedDate: new Date().toISOString(),
    yourResponse: "waiting",
    requestStatus: "new",
  },
  {
    id: 3,
    requestId: "#1234567890",
    consumerName: "Consumer Name 1",
    contact: "9876543210",
    description: "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
    category: "Food Relief",
    via: "call",
    priorityLevel: "high",
    intakeDate: new Date().toISOString(),
    requestedBy: "Agent",
    receivedDate: new Date().toISOString(),
    yourResponse: "waiting",
    requestStatus: "new",
  },
];

function Grid() {
  return (
    <div className="mt-7 p-2 shadow-lg rounded">
      <div className="p-4 flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Requests</h2>
        <div className="flex gap-8">
          <div className="relative">
            <input
              type="text"
              className="block w-full p-2 text-sm text-gray-900 border-none"
              placeholder="Search here..."
              style={{
                border: "1px solid rgba(29, 91, 191, 1)",
                borderRadius: "3px",
                height: "58px",
                width: "350px",
              }}
              onChange={(e) => console.log(e)}
            />
            <div className="absolute right-2 top-3">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
          <button className="bg-secondary-blue text-primary-blue px-8 py-4 rounded">
            View All
          </button>
        </div>
      </div>
      <div className="border-t-2 border-t-gray-bg my-2"></div>
      <div className="p-4">
        <DataGrid columns={columns} rows={data} />
      </div>
    </div>
  );
}

export default Grid;
