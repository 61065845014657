import React from 'react'
import { useNavigate } from 'react-router'

import donationIcon from '../../assets/donation-icon.svg'
import mentorIcon from '../../assets/mentor-icon.svg'
import aidIcon from '../../assets/aid-icon.svg'
import advocacyIcon from '../../assets/advocacy-icon.svg'
import housingIcon from '../../assets/housing-repair-icon.svg'
import childcareIcon from '../../assets/childcare-icon.svg'
import counselingIcon from '../../assets/counseling-icon.svg'


export default function TopCategory() {
    const navigate = useNavigate()
    const frequentCategory = [
        {
            name: 'Donations Request',
            key: 'donation',
            img: donationIcon
        },
        {
            name: 'Mentorship Request',
            key: 'mentor',
            img: mentorIcon
        },
        {
            name: 'AID Request',
            key: 'aid',
            img: aidIcon
        },
        {
            name: 'Advocacy Request',
            key: 'advocacy',
            img: advocacyIcon
        },
        {
            name: 'Housing Repair',
            key: 'housing',
            img: housingIcon
        },
        {
            name: 'Childcare Support',
            key: 'childcare',
            img: childcareIcon
        },
        {
            name: 'Counseling Request',
            key: 'counseling',
            img: counselingIcon
        },
        {
            name: 'AID Request',
            key: 'aid',
            img: aidIcon
        },
        {
            name: 'Advocacy Request',
            key: 'advocacy',
            img: advocacyIcon
        },
        {
            name: 'Housing Repair',
            key: 'housing',
            img: housingIcon
        },
        {
            name: 'Childcare Support',
            key: 'childcare',
            img: childcareIcon
        },
        {
            name: 'Counseling Request',
            key: 'counseling',
            img: counselingIcon
        },
    ]
    return (
        <div style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.05)', borderRadius: '10px' }} className='mt-5'>
            <div className="title flex justify-between py-3 px-4 items-center" style={{ borderBottom: '1px solid #2260D9' }}>
                <div className="flex gap-4 items-center w-full justify-between">
                    <h4 className='text-[20px]'>Top Categories</h4>

                    <div style={{ background: 'rgba(210, 228, 255, 1)', color: 'rgba(34, 96, 217, 1)', borderRadius: '3px' }}
                        className='py-1 px-3 cursor-pointer'
                    >
                        View all
                    </div>
                </div>
            </div>
            <div className='flex px-4 gap-10 py-7 flex-wrap'>
                {
                    frequentCategory.map((freqCat) => {
                        return (
                            <div className='flex flex-col gap-3 items-center'>
                                <div style={{
                                    boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                    padding: '25px 15px', borderRadius: '5px',
                                    textAlign: 'center',
                                    width: '100px',
                                    height: '100px'
                                }} className='flex flex-col gap-4 cursor-pointer' key={freqCat.key} onClick={() => navigate('/category/1')}>
                                    <img src={freqCat.img} className='h-9' alt="DonationIcon" />
                                </div>
                                <p>Membership</p>
                            </div>
                        )
                    })

                }

                <div style={{
                    boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                    padding: '25px 24px', borderRadius: '5px',
                    textAlign: 'center',
                    width: '100px',
                     height: '100px',
                    background: 'rgba(219, 231, 255, 1)',
                    color: 'rgba(34, 96, 217, 1)',
                    border: '1px solid rgba(34, 96, 217, 1)'
                }} className='flex flex-col gap-4 cursor-pointer' >
                    Show more...
                </div>

            </div>
        </div>
    )
}

