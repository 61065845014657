import {
    createAction,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import {
    jwtDecode
  } from "jwt-decode";

import api from "./api";


export const updateUserInfo = createAction('update/updateUserInfo')

export const updateUserType = createAction('update/updateUserType')


export const loginSocialMedia = createAsyncThunk(
    "loginSocialMedia",
    async (data) => {
        const loginSocial = await api.post('/user/social-login/', data);
        console.log('loginSocial', loginSocial)
        if (loginSocial.status === 200 && loginSocial.data) {
            let accessToken = loginSocial.data?.result?.access_token || ''

            if(accessToken !== '' && accessToken !== undefined){
                localStorage.setItem("access_token", loginSocial.data.result.access_token);
                let decoded = jwtDecode(loginSocial.data.result.access_token);
                return { status: loginSocial.status, userResponse: decoded };
            }

        }
        return loginSocial
    }
);


export const updateInfo = createAsyncThunk(
    "updateInfo",
    async () => {
      const userToken = localStorage.getItem("access_token");
      if (userToken) {
        let decoded = jwtDecode(userToken);
        return decoded;
      }
      return {};
    }
  );