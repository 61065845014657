import Cards from "./Cards";
import Grid from "./Grid";

function PartnerDashboard() {
  return (
    <main className="px-[60px] py-[70px]">
      <Cards />
      <Grid />
    </main>
  );
}

export default PartnerDashboard;
