import React from 'react'
import MuiModal from '../../../shared/Modal'
import Tooltip from '../../../shared/Tooltip'

import CloseIcon from '../../../assets/close-red-icon.svg'

export default function CancelRequest({ open, closeModal }) {
    return (
        <MuiModal modalSize="md" modalOpen={open} modalClose={closeModal} noheader>
            <div>
                <div className="title py-3 px-2" style={{ borderBottom: '1px solid rgba(214, 214, 214, 1)' }}>
                    <div className="flex items-center justify-between">
                        <p className='text-primary-red font-medium text-[18px]'>Are You Sure Want to Cancel Request ?</p>
                        <Tooltip title="Cancel">
                            <img src={CloseIcon} className='cursor-pointer' onClick={closeModal} alt="CloseIcon" />
                        </Tooltip>
                    </div>


                </div>

                <div className='flex gap-5 px-5 py-6 '>
                    <div className='flex flex-col gap-6 relative w-[30%]'>
                        <div className='flex gap-6 items-center'>
                            <input type='checkbox' className='h-[30px] w-[30px]' style={{ border: '1px solid rgba(210, 226, 255, 1)' }} />
                            <p>Incorrect Info</p>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <input type='checkbox' className='h-[30px] w-[30px]' style={{ border: '1px solid rgba(210, 226, 255, 1)' }} />
                            <p>Aid No Longer Needed</p>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <input type='checkbox' className='h-[30px] w-[30px]' style={{ border: '1px solid rgba(210, 226, 255, 1)' }} />
                            <p>Urgency Reduced</p>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <input type='checkbox' className='h-[30px] w-[30px]' style={{ border: '1px solid rgba(210, 226, 255, 1)' }} />
                            <p>Conflict of Needs</p>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <input type='checkbox' className='h-[30px] w-[30px]' style={{ border: '1px solid rgba(210, 226, 255, 1)' }} />
                            <p>Other</p>
                        </div>

                    </div>

                    <div className='w-[60%]'>
                        <textarea className='w-full h-full px-2 py-3' style={{ border: '1px solid rgba(214, 229, 238, 1)' }}
                            placeholder='Enter your valid reason for Cancelling Request'></textarea>
                    </div>
                </div>

                <div className='flex justify-center items-center gap-6 my-6'>
                    <button style={{ border: '1px solid rgba(253, 200, 205, 1)', borderRadius: '3px' }} className='w-[150px] py-3' onClick={closeModal}>No</button>
                    <button className='bg-primary-red text-white w-[150px] py-3' style={{borderRadius: '3px'}}  onClick={closeModal}>Yes</button>
                </div>
            </div>
        </MuiModal>
    )
}
