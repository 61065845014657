import React from 'react'
import { useNavigate } from 'react-router'

import BreadCrumIcon from '../../assets/breadcrumb-right-icon.svg'
import LocationIcon from '../../assets/location-red-icon.svg'
import CalendarIcon from '../../assets/calendar-1-icon.svg'
import PhoneCircleIcon from '../../assets/phone-circle-icon.svg'
import EmailCircleIcon from '../../assets/email-circle-icon.svg'
import UserCircleIcon from '../../assets/user-circle-icon.svg'
import LinkedInIcon from '../../assets/linkedin-icon.svg'
import TwitterIcon from '../../assets/twitter-icon.svg'
import FacebookIcon from '../../assets/facebook-icon.svg'
import SearchIcon from '../../assets/input-search-icon.svg'
import DataTable from '../../shared/DataGrid'
import { TotalEmployeesColumn } from '../../utills/tableFields'


export default function ViewPartner() {
    const navigate = useNavigate()
    return (
        <div className="px-8 mt-10">

            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/partners')}>
                                Partner
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">Partner Details</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>


            <div className='px-5 py-5 mb-5 details-info' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                <div className='flex items-center gap-4 pb-4' style={{ borderBottom: '1px solid rgba(228, 230, 239, 1)' }}>
                    <p style={{ borderRight: '1px solid rgba(34, 96, 217, 1)', paddingRight: '20px' }} className='text-primary-blue font-medium text-[22px]'>
                        ABCD Welfare And Awareness
                    </p>
                    <p>ID Number: #HCA00012345</p>
                    <button className='bg-light-green w-[170px] py-2 text-primary-green' style={{ borderRadius: '3px' }}>Active</button>
                </div>

                <div className='py-8 flex'>
                    <p style={{ borderRight: '1px solid rgba(53, 63, 79, 1)' }} className='flex gap-3 pr-3 items-center'>
                        <img src={LocationIcon} alt="LocationIcon" />
                        <span className='text-[13px]'>America</span>
                    </p>
                    <div className='flex gap-3 px-3 items-center'>
                        <img src={CalendarIcon} alt="CalendarIcon" />
                        <span className='text-[13px]'>Request Date & Time Jun 5th and 6:00Pm</span>
                    </div>
                </div>

                <div className='py-1'>
                    <p className='pb-3'>Primary Contact:</p>
                    <div className='flex gap-3'>
                        <div className='flex gap-3 items-center'>
                            <img src={PhoneCircleIcon} alt="PhoneCircleIcon" />
                            <span className='underline text-[12px]'>+1 43 456890</span>
                        </div>
                        <div style={{ border: '1px solid rgba(53, 63, 79, 1)' }}></div>

                        <div className='flex gap-3 items-center'>
                            <img src={EmailCircleIcon} alt="EmailCircleIcon" />
                            <span className='text-[12px]'>CatrinaFuture@gmail.com</span>
                        </div>
                        <div style={{ border: '1px solid rgba(53, 63, 79, 1)' }}></div>

                        <div className='flex gap-3 items-center'>
                            <img src={UserCircleIcon} alt="UserCircleIcon" />
                            <span className='text-[12px]' >CatrinaFuture@gmail.com / <span className='underline'>+1 43 456890</span></span>
                        </div>
                    </div>
                </div>

                <div className='pt-5'>
                    <p className='pb-3'>Secondary Contact:</p>
                    <div className='flex gap-3'>
                        <div className='flex gap-3 items-center'>
                            <img src={PhoneCircleIcon} alt="PhoneCircleIcon" />
                            <span className='underline text-[12px]'>+1 43 456890</span>
                        </div>
                        <div style={{ border: '1px solid rgba(53, 63, 79, 1)' }}></div>

                        <div className='flex gap-3 items-center'>
                            <img src={EmailCircleIcon} alt="EmailCircleIcon" />
                            <span className='text-[12px]'>CatrinaFuture@gmail.com</span>
                        </div>
                    </div>
                </div>


                <div className='pt-10 pb-5 text-[12px]'>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap
                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop.
                </div>

                <div className='text-[14px] py-5'>
                    <p>Website Link:</p>
                    <p className='underline text-primary-blue font-medium'>https://git.drupalcode.org</p>
                </div>

                <div className='flex items-center gap-5 text-[14px]'>
                    <p>Social Media : </p>
                    <div className='flex gap-4 items-center'>
                        <img src={LinkedInIcon} alt="LinkedInIcon" />
                        <img src={TwitterIcon} alt="TwitterIcon" />
                        <img src={FacebookIcon} className='h-7' alt="FacebookIcon" />
                    </div>
                </div>

                <div className='pt-10 pb-5'>
                    <p style={{ borderBottom: '1px solid rgba(193, 196, 196, 1)' }} className='text-primary-blue pb-2 font-semibold'>Provided Categories</p>
                    <div className='flex gap-16 py-4 px-4'>
                        <ul className='text-[14px] leading-10 list-disc'>
                            <li>Educational Support Aid</li>
                            <li>Food Security Aid</li>
                            <li>Water and Sanitation Aid</li>
                            <li>Disaster Preparedness Aid</li>
                        </ul>
                        <ul className='text-[14px] leading-10 list-disc'>
                            <li>Educational Support Aid</li>
                            <li>Food Security Aid</li>
                            <li>Water and Sanitation Aid</li>
                            <li>Disaster Preparedness Aid</li>
                        </ul>
                        <ul className='text-[14px] leading-10 list-disc'>
                            <li>Educational Support Aid</li>
                            <li>Food Security Aid</li>
                            <li>Water and Sanitation Aid</li>
                            <li>Disaster Preparedness Aid</li>
                        </ul>
                    </div>
                </div>


                <div className='pt-1 pb-5'>

                    <div className='flex justify-between items-center' style={{ borderBottom: '1px solid rgba(193, 196, 196, 1)' }}>
                        <p className='text-primary-blue pb-2 font-semibold'>Total Employees</p>
                        <div className="relative pb-2">
                            <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                placeholder="Search here..." style={{
                                    border: '1px solid rgba(34, 96, 217, 1)',
                                    height: '50px',
                                    width: '300px'
                                }} />
                            <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                                <img src={SearchIcon} alt='SearchIcon' />
                            </div>
                        </div>
                    </div>
                    <div className='py-8'>
                        <DataTable row={[]} columns={TotalEmployeesColumn} hideCheckbox />
                    </div>
                </div>



            </div>

        </div>
    )
}
