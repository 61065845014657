import React, { useState } from 'react'

import SearchIcon from '../../assets/input-search-icon.svg'
import DataTable from '../../shared/DataGrid';
import { TotalPartnerColumn, TotalRequestColumn } from '../../utills/tableFields';

export default function AgentView() {
    const [actionTab, setActiveTab] = useState('total-request');
    const actionTabFilter = [{
        key: 'total-request',
        name: 'Total Request'
    },
    {
        key: 'total-partners',
        name: 'Total Partners'
    }
    ]
    return (
        <div className='px-5 py-5 mb-5 details-info' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
            <div className='flex items-center gap-4 pb-4' style={{ borderBottom: '1px solid rgba(228, 230, 239, 1)' }}>
                <p style={{ borderRight: '1px solid rgba(34, 96, 217, 1)', paddingRight: '20px' }} className='text-primary-blue font-medium text-[22px]'>
                    Emergency Relief Aid
                </p>
                <p>Category Id: #HCA00012345</p>
            </div>
            <div className='text-[14px] py-8'>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop.
            </div>

            <div className='flex justify-between  mb-8 items-center' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                <ul className='tab-list'>
                    {
                        actionTabFilter.map((discussion, index) =>
                            <li className={`${actionTab === discussion.key ? 'active' : ''} relative !w-[110px]`} key={index}
                                onClick={() => setActiveTab(discussion.key)}
                            >
                                <div className='text-[13px]'> {`${discussion.name}`}</div>
                                {actionTab === discussion.key && <span></span>}
                            </li>)
                    }
                </ul>
            </div>

            <div className='flex justify-end items-center'>
                <div className="relative">
                    <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                        placeholder="Search here..." style={{
                            border: '1px solid rgba(34, 96, 217, 1)',
                            height: '50px',
                            width: '300px'
                        }} />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                        <img src={SearchIcon} alt='SearchIcon' />
                    </div>
                </div>
            </div>

            <div className='py-8'>
                <DataTable row={[]} columns={actionTab === 'total-request' ? TotalRequestColumn : TotalPartnerColumn} hideCheckbox />
            </div>
        </div>
    )
}
