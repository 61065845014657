import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "../../assets/search-icon.svg";
import aidIcon from "../../assets/aid-icon.svg";
import chatIcon from "../../assets/chat.svg";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export const RequestTabs = [
  {
    name: "Accepted",
    key: "accepted",
  },
  {
    name: "Completed",
    key: "completed",
  },
  {
    name: "Rejected",
    key: "rejected",
  },
];

function PartnerTrackingView() {
  const [activeTab, setActiveTab] = useState("all");

  return (
    <main className="p-10">
      <p>Request</p>
      <div className="mt-7 p-6 rounded flex flex-col border border-light-gray">
        <div className="details-info px-5 mb-8 border-b">
          <ul className="tab-list">
            {RequestTabs.map((discussion, index) => (
              <li
                className={`${
                  activeTab === discussion.key ? "active" : ""
                } relative`}
                key={discussion.key}
                onClick={() => setActiveTab(discussion.key)}
              >
                <div className="flex justify-center pb-1">
                  <div
                    className={`total-proram-count relative ${
                      activeTab === discussion.key ? "active" : ""
                    }`}
                  >
                    10
                  </div>
                </div>
                <div className="text-[13px]"> {`${discussion.name}`}</div>
                {activeTab === discussion.key && <span></span>}
              </li>
            ))}
          </ul>
        </div>
        <div className="relative w-max self-end">
          <input
            type="text"
            className="block w-full p-2 text-sm text-gray-900 border-none"
            placeholder="Search here..."
            style={{
              border: "1px solid rgba(29, 91, 191, 1)",
              borderRadius: "3px",
              height: "58px",
              width: "350px",
            }}
            onChange={(e) => console.log(e)}
          />
          <div className="absolute right-2 top-3">
            <img src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>
        <div className="mt-6 rounded border border-light-gray">
          <div className="p-6 flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-6">
                <img
                  src={aidIcon}
                  style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
                  className="shadow-2xl p-2 rounded"
                />
                <h2 className="text-4xl font-semibold">AID Request</h2>
                <span className="px-8 py-2 border border-[#FFCD11] text-[#FFCD11] bg-[#ffcb1110] rounded-3xl">
                  In Progress
                </span>
              </div>
              <div className="flex gap-4">
                <button className="border border-light-gray p-2 rounded">
                  <img src={chatIcon} />
                </button>
                <button className="bg-primary-blue text-white px-4 rounded-sm flex items-center gap-8">
                  Accepted
                  <KeyboardArrowDownIcon className="text-white" />
                </button>
              </div>
            </div>
            <div className="flex gap-4 ml-[92px]">
              <div className="flex gap-2">
                <span>Request ID: </span>
                <span className="text-primary-blue underline">#1234567890</span>
              </div>
              <div className="h-6 border-r-2 border-r-light-gray"></div>
              <div className="flex gap-2">
                <span>Request Date: </span>
                <span className="text-primary-blue underline">12/12/2024</span>
              </div>
              <div className="h-6 border-r-2 border-r-light-gray"></div>
              <div className="flex gap-2">
                <span>Accepted Date: </span>
                <span className="text-primary-blue underline">12/12/2024</span>
              </div>
            </div>
            <Link
              to="/requests/requestId"
              className="ml-[92px] underline text-primary-blue"
            >
              View Detail Page
            </Link>

            <div className="relative bg-[#C8DBFF] h-2 w-[80%] self-center mt-6 mb-28">
              <span className="absolute bg-primary-blue w-1/2 h-2 block"></span>
              <CheckCircleIcon
                fontSize="large"
                className="absolute -top-[14px] left-0 bg-white"
                color="success"
              />
              <div className="absolute top-8 max-w-[150px] text-center flex flex-col gap-2 -left-12">
                <p className="font-medium">Request Received</p>
                <p className="text-sm text-primary-black/90 text-center">
                  Your request has been successfully sent to Partner
                </p>
              </div>
              <CheckCircleIcon
                fontSize="large"
                className="absolute -top-[14px] left-1/2 bg-white"
                color="success"
              />
              <div className="absolute top-8 max-w-[150px] text-center flex flex-col gap-2 left-[46%]">
                <p className="font-medium">Request Accepted</p>
                <p className="text-sm text-primary-black/90 text-center">
                  Your request has been successfully sent to Partner
                </p>
              </div>
              <RadioButtonUncheckedIcon
                fontSize="large"
                className="absolute text-[#C8DBFF] -right-2 -top-[14px] bg-white"
              />
              <div className="absolute top-8 max-w-[150px] text-center flex flex-col gap-2 -right-16">
                <p>Request Completed</p>
              </div>
            </div>
          </div>
          <div className="p-6 border-t-2 border-t-light-gray flex flex-col gap-10">
            <div className="flex gap-6">
              <div className="flex gap-6 items-center w-[35%]">
                <img
                  style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
                  className="p-4"
                  src={aidIcon}
                />
                <div className="flex flex-col gap-2">
                  <p>Donations Request</p>
                  <span>In Progress</span>
                </div>
              </div>
              <div className="border-r border-r-light-gray"></div>
              <div className="flex gap-4 justify-between items-center rounded w-full">
                <div className="flex flex-col flex-grow gap-4">
                  <p>
                    <span>Request ID : </span>
                    <span>1234567890</span>
                  </p>
                  <p className="text-wrap text-sm">
                    This NGO truly makes a difference! Their commitment to
                    empowering communities is evident in the tangible results
                    they deliver. I've seen firsthand how their projects have
                    transformed lives, and I couldn't be more impressed with
                    their dedication and transparency.
                  </p>
                </div>
                <Link to="/partner/requests/abc">
                  <button className="bg-primary-blue text-white px-6 py-2 h-max text-nowrap">
                    View Request Detail
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex gap-6 items-center w-[35%]">
                <img
                  style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
                  className="p-4"
                  src={aidIcon}
                />
                <div className="flex flex-col gap-2">
                  <p>Donations Request</p>
                  <span>In Progress</span>
                </div>
              </div>
              <div className="border-r border-r-light-gray"></div>
              <div className="flex gap-4 justify-between items-center rounded w-full">
                <div className="flex flex-col flex-grow gap-4">
                  <p>
                    <span>Request ID : </span>
                    <span>1234567890</span>
                  </p>
                  <p className="text-wrap text-sm">
                    This NGO truly makes a difference! Their commitment to
                    empowering communities is evident in the tangible results
                    they deliver. I've seen firsthand how their projects have
                    transformed lives, and I couldn't be more impressed with
                    their dedication and transparency.
                  </p>
                </div>
                <Link to="/partner/requests/abc">
                  <button className="bg-primary-blue text-white px-6 py-2 h-max text-nowrap">
                    View Request Detail
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex gap-6 items-center w-[35%]">
                <img
                  style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
                  className="p-4"
                  src={aidIcon}
                />
                <div className="flex flex-col gap-2">
                  <p>Donations Request</p>
                  <span>In Progress</span>
                </div>
              </div>
              <div className="border-r border-r-light-gray"></div>
              <div className="flex gap-4 justify-between items-center rounded w-full">
                <div className="flex flex-col flex-grow gap-4">
                  <p>
                    <span>Request ID : </span>
                    <span>1234567890</span>
                  </p>
                  <p className="text-wrap text-sm">
                    This NGO truly makes a difference! Their commitment to
                    empowering communities is evident in the tangible results
                    they deliver. I've seen firsthand how their projects have
                    transformed lives, and I couldn't be more impressed with
                    their dedication and transparency.
                  </p>
                </div>
                <Link to="/partner/requests/abc">
                  <button className="bg-primary-blue text-white px-6 py-2 h-max text-nowrap">
                    View Request Detail
                  </button>
                </Link>
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex gap-6 items-center w-[35%]">
                <img
                  style={{ boxShadow: "4px 4px 25px 0px rgba(0, 0, 0, 0.15)" }}
                  className="p-4"
                  src={aidIcon}
                />
                <div className="flex flex-col gap-2">
                  <p>Donations Request</p>
                  <span>In Progress</span>
                </div>
              </div>
              <div className="border-r border-r-light-gray"></div>
              <div className="flex gap-4 justify-between items-center rounded w-full">
                <div className="flex flex-col flex-grow gap-4">
                  <p>
                    <span>Request ID : </span>
                    <span>1234567890</span>
                  </p>
                  <p className="text-wrap text-sm">
                    This NGO truly makes a difference! Their commitment to
                    empowering communities is evident in the tangible results
                    they deliver. I've seen firsthand how their projects have
                    transformed lives, and I couldn't be more impressed with
                    their dedication and transparency.
                  </p>
                </div>
                <Link to="/partner/requests/abc">
                  <button className="bg-primary-blue text-white px-6 py-2 h-max text-nowrap">
                    View Request Detail
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PartnerTrackingView;
