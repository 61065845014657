import React from 'react'
import CallIcon from '../../assets/request-phone-icon.svg'
import FileIcon from '../../assets/file-white-icon.svg'
import PhoneIcon from '../../assets/phone-green.icon.svg'
import DoubleArrowDownIcon from '../../assets/double-down-arrow-blue.svg'
import AvatarGirlIcon from '../../assets/avatar-lady-icon.svg'
import SampleCategoryIcon from '../../assets/sample-category-icon.svg'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@mui/material'

export default function ConsumerView() {
    const navigate = useNavigate()
    const { details } = useSelector(state => state.categoryInfo)
    return (
        <div className="grid grid-cols-6 gap-5 py-5">
           
            <div className="col-span-4">
                <div className='px-10 py-10 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                    <div className='flex gap-4 items-start'>
                        <img src={CallIcon} alt="FileIcon" />
                        <div className='flex flex-col'>
                            <div className='flex justify-between w-full'>
                                <p className='text-[24px] font-bold'>{details.name}</p>
                                <div className='flex gap-3'>
                                    <button className='bg-primary-green text-white flex gap-3 justify-center items-center py-4 px-5 w-[200px]' style={{ borderRadius: '3px' }}>
                                        <img src={PhoneIcon} alt="PhoneIcon" />
                                        <span>Call</span>
                                    </button>
                                    <button className='bg-primary-blue text-white flex gap-3 justify-center items-center py-4 px-5 w-[200px]' style={{ borderRadius: '3px' }}
                                        onClick={() => navigate('/create-request')}>
                                        <img src={FileIcon} alt="FileIcon" />
                                        <span> Request</span>
                                    </button>
                                </div>
                            </div>
                            <div className='pt-7'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                                into electronic typesetting, remaining essentially unchanged.
                                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem
                            </div>
                            <div className='flex justify-center items-center flex-col gap-2 mt-20 cursor-pointer'>
                                <p>Show More</p>
                                <img src={DoubleArrowDownIcon} alt="DoubleArrowDownIcon" />
                            </div>
                        </div>

                    </div>

                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='py-7 px-6 mt-10 post-comments'>
                        <h3 className='text-[18px]'>Comments(02)</h3>

                        <div className='add-comments relative'>
                            <img src={AvatarGirlIcon} alt="UserIcon" />
                            <input className='comment-input' type="text" value={''} placeholder='Add Comment...'
                                onChange={undefined} />
                        </div>

                        <div>

                            <div className='post-list-comments'>
                                <img className='user-img' src={AvatarGirlIcon} alt="Userimage" />
                                <div style={{ width: 'calc(100% - 50px)', paddingLeft: '12px' }}>
                                    <div className='flex gap-3 items-center justify-start'>
                                        <p className='text-[14px] capitalize'>
                                            <span>Johnson</span>
                                        </p>
                                        <p className='text-[10px]'>2months ago</p>
                                    </div>
                                    <div className='py-2 my-2 text-[13px]' >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat
                                    </div>
                                </div>
                            </div>

                            <div className='post-list-comments'>
                                <img className='user-img' src={AvatarGirlIcon} alt="Userimage" />
                                <div style={{ width: 'calc(100% - 50px)', paddingLeft: '12px' }}>
                                    <div className='flex gap-3 items-center justify-start'>
                                        <p className='text-[14px] capitalize'>
                                            <span>Johnson</span>
                                        </p>
                                        <p className='text-[10px]'>2months ago</p>
                                    </div>
                                    <div className='py-2 my-2 text-[13px]' >
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                        sed do eiusmod tempor incididunt nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='col-span-2'>
                <div className='mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                    <div style={{ borderBottom: '1px solid rgba(228, 237, 255, 1)' }} className='flex justify-between px-7 py-5 items-center'>
                        <p className='text-[20px]'>Related Categories</p>
                        <button className='bg-secondary-blue py-2 px-3 text-primary-blue' style={{ borderRadius: '3px' }}>View More</button>
                    </div>

                    {
                        Array.from({ length: 5 }, (_, i) => i + 1).map(i => {
                            return (
                                <div className='py-4 px-6 flex gap-9' key={i} style={{ borderBottom: '1px solid rgba(228, 237, 255, 1)' }}>
                                    <div className='bg-secondary-blue flex justify-center items-center w-[200px]'>
                                        <img src={SampleCategoryIcon} alt="SampleCategoryIcon" />
                                    </div>
                                    <div className='flex flex-col gap-4'>
                                        <p className='text-[18px] font-medium'>Medical AID</p>
                                        <p className='text-[12px]'>Immediate Care for Urgent Needs</p>
                                        <button className='bg-primary-blue text-white py-3 mt-2 px-4 w-[150px] cursor-pointer' style={{ borderRadius: '3px' }}>View Details</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
