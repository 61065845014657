export const RequestColumn = [{
    field: "full_name",
    headerName: "Request ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "No. of Beneficiaries",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Via",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Submitted Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "last_attend_program",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "last_attend_program",
    headerName: "Action",
    flex: 1,
    id: 1,
  },
];


export const FrequentRequestColumn = [{
    field: "full_name",
    headerName: "Category ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Total Partners",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Total Request",
    flex: 1,
    id: 1,
  }
];


export const CurrentStatusColumn = [{
    field: "full_name",
    headerName: "OPT",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Request ID",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Client Name",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Via",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Intake Date",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Level",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Submitted Date",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Status",
    flex: 1,
    id: 1,
  }, {
    field: "email",
    headerName: "Partner",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Partner Response",
    flex: 1,
    id: 1,
  },
  {
    field: "email",
    headerName: "Action",
    flex: 1,
    id: 1,
  }
];



export const PartnerColumn = [{
    field: "full_name",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Organization Name",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Partner Type",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Managed Category",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  }
];



export const CategoryColumn = [{
    field: "full_name",
    headerName: "Category ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Category",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Total Partners",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Total Managed Request",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Description",
    flex: 1,
    id: 2,
  }
];


export const TotalRequestColumn = [{
    field: "full_name",
    headerName: "OPT",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Request ID",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Client Name",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Contact",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Via",
    flex: 1,
    id: 2,
  },
  {
    field: "location",
    headerName: "Intake Date",
    flex: 1,
    id: 2,
  },
  {
    field: "attn_program",
    headerName: "Level",
    flex: 1,
    id: 2,
  },
  {
    field: "desc",
    headerName: "Description",
    flex: 1,
    id: 1,
  },
  {
    field: "submit",
    headerName: "Submitted Date",
    flex: 1,
    id: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "partner",
    headerName: "Partner",
    flex: 1,
    id: 1,
  },
  {
    field: "response",
    headerName: "Partner Response",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 1,
  },

];


export const TotalPartnerColumn = [{
    field: "full_name",
    headerName: "Partner ID",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Name",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Total Employees",
    flex: 1,
    id: 2,
  },
  {
    field: "email",
    headerName: "Managed Category",
    flex: 1,
    id: 1,
  },
  {
    field: "location",
    headerName: "Last Update Date",
    flex: 1,
    id: 2,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    id: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    id: 2,
  }
];



export const TotalEmployeesColumn = [{
    field: "full_name",
    headerName: "Agent Name",
    flex: 1,
    id: 0,
  },
  {
    field: "Professional_Bio",
    headerName: "Email",
    flex: 1,
    id: 1,
  },
  {
    field: "phone_number",
    headerName: "Contact",
    flex: 1,
    id: 2,
  }

];


