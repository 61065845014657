import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
        dispatch({ type: "logout" })
    }

    useEffect(() => {
        navigate("/");
    }, [])
    return (
        <></>
    )
}
