import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

import BreadCrumIcon from '../../assets/breadcrumb-right-icon.svg'
import SearchIcon from '../../assets/input-search-icon.svg'
import donationIcon from '../../assets/donation-icon.svg'
import mentorIcon from '../../assets/mentor-icon.svg'
import aidIcon from '../../assets/aid-icon.svg'
import advocacyIcon from '../../assets/advocacy-icon.svg'
import housingIcon from '../../assets/housing-repair-icon.svg'
import childcareIcon from '../../assets/childcare-icon.svg'
import counselingIcon from '../../assets/counseling-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../shared/DataGrid'
import AgentCategory from './AgentCategory'
import ConsumerCategory from './ConsumerCategory'
import { getAllCategory, getAllCategoryList } from '../../services/category'


export default function Category() {
    const navigate = useNavigate()
    const { userType } = useSelector(state => state.userInfo)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getAllCategory())
    },[])

    return (
        <div className="dashboard-content px-8 mt-10">

            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/')}>
                                Dashboard
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">Categories</span>
                            </div>
                        </li>
                    </ol>
                </nav>

            </div>



            <div className='px-3 py-5 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                <div className='flex justify-between px-5 pb-4 mb-8 items-center' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                    <div className='flex gap-5 items-center '>
                        <p>All Categories</p>
                    </div>
                    <div className='flex gap-8 items-center'>
                        <div className="relative">
                            <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                placeholder="Search here..." style={{
                                    border: '1px solid rgba(34, 96, 217, 1)',
                                    height: '50px',
                                    width: '300px'
                                }} />
                            <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                                <img src={SearchIcon} alt='SearchIcon' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mx-5'>
                    {
                        userType === 'consumer' &&
                        <ConsumerCategory />
                    }

                    {
                        userType === 'other' &&
                        <AgentCategory />
                    }

                </div>
            </div>


        </div>
    )
}
