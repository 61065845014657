import React from 'react'
import donationIcon from '../../assets/donation-icon.svg'
import mentorIcon from '../../assets/mentor-icon.svg'
import aidIcon from '../../assets/aid-icon.svg'
import advocacyIcon from '../../assets/advocacy-icon.svg'
import housingIcon from '../../assets/housing-repair-icon.svg'
import childcareIcon from '../../assets/childcare-icon.svg'
import counselingIcon from '../../assets/counseling-icon.svg'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@mui/material'

export default function ConsumerCategory() {
    const navigate = useNavigate()
    const { data, loading } = useSelector(state => state.categoryInfo)
    const frequentCategory = [
        {
            name: 'Donations Request',
            key: 'donation',
            img: donationIcon
        },
        {
            name: 'Mentorship Request',
            key: 'mentor',
            img: mentorIcon
        },
        {
            name: 'AID Request',
            key: 'aid',
            img: aidIcon
        },
        {
            name: 'Advocacy Request',
            key: 'advocacy',
            img: advocacyIcon
        },
        {
            name: 'Housing Repair',
            key: 'housing',
            img: housingIcon
        },
        {
            name: 'Childcare Support',
            key: 'childcare',
            img: childcareIcon
        },
        {
            name: 'Counseling Request',
            key: 'counseling',
            img: counselingIcon
        },
        {
            name: 'AID Request',
            key: 'aid',
            img: aidIcon
        }
    ]
    return (
        <>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div class="grid grid-cols-4 gap-4">
                <div style={{ border: '1px solid rgba(34, 96, 217, 1)', borderRadius: '3px' }} className='py-4 pl-6'>
                    <ul className='category-list'>
                        {
                            data.map((category, i) => <li onClick={() => navigate(`/category/${category.id}`)}>{category.name}</li>)
                        }

                    </ul>
                </div>
            </div>


            <div className='my-5'>
                <div className="title flex justify-between py-3 items-center" style={{ borderBottom: '1px solid #2260D9' }}>
                    <div className="flex">
                        <h4 className='text-[18px]'>Recent Request</h4>
                    </div>
                </div>
                <div className='flex gap-8 py-7 flex-wrap'>
                    {
                        frequentCategory.map((freqCat) => {
                            return (
                                <div className='flex flex-col gap-3 items-center'>
                                    <div style={{
                                        boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                        padding: '25px 15px', borderRadius: '5px',
                                        textAlign: 'center',
                                        width: '100px',
                                        height: '100px'
                                    }} className='flex flex-col gap-4 cursor-pointer' key={freqCat.key}>
                                        <img src={freqCat.img} className='h-9' alt="DonationIcon" />
                                    </div>
                                    <p>Membership</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
