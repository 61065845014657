import {
    createSlice
} from "@reduxjs/toolkit";
import {
    categoryApiResponse
} from "../../constants/apiResponse";
import {
    getAllCategory,
    getAllCategoryList,
    getCategoryById
} from "../../services/category";


const initialState = {
    data: [],
    details: {},
    loading: false,
    status: '',
    error: "",
};

export const categorySlice = createSlice({
    name: "categoryInfo",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategory.pending, (state) => {
                return {
                    ...state,
                    loading: true
                };
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                    details: {},
                    status: categoryApiResponse.load,
                    loading: false
                };
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.error.message,
                };
            })

        builder
            .addCase(getCategoryById.pending, (state) => {
                return {
                    ...state,
                    loading: true
                };
            })
            .addCase(getCategoryById.fulfilled, (state, action) => {
                return {
                    ...state,
                    details: action.payload,
                    status: categoryApiResponse.details,
                    loading: false
                };
            })
            .addCase(getCategoryById.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.error.message,
                };
            })


    },
});


export default categorySlice.reducer;