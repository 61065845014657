import React, { useState } from 'react';

import SearchIcon from '../../../assets/input-search-icon.svg'
import CallIcon from '../../../assets/request-phone-icon.svg'
import ArrowDownIcon from '../../../assets/arrow-down-icon.svg'
import DonationIcon from '../../../assets/donation-icon.svg'
import ChildcareIcon from '../../../assets/childcare-icon.svg'
import HousingIcon from '../../../assets/housing-repair-icon.svg'
import ListIcon from '../../../assets/list-icon.svg'
import { useNavigate } from 'react-router';
import CancelRequest from './CancelRequest';

export default function RequestDetails({ handleView }) {
    const navigate = useNavigate()
    const [actionTab, setActiveTab] = useState('inprogress');
    const [detailAction, setDetailAction] = useState({ cancelModal: false })

    const handleChange = (tab) => {
        setActiveTab(tab);
    };

    const closeModal = () => {
        setDetailAction({ cancelModal: false })
    }

    const actionTabFilter = [{
        key: 'inprogress',
        name: 'In-Progress'
    },
    {
        key: 'completed',
        name: 'Completed'
    },
    {
        key: 'rejected',
        name: 'Rejected'
    }
    ]

    return (
        <div className='px-6 py-2 details-info'>
            <div className='flex justify-between  mb-8 items-center' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                <ul className='tab-list'>
                    {
                        actionTabFilter.map((discussion, index) =>
                            <li className={`${actionTab === discussion.key ? 'active' : ''} relative`} key={index}
                                onClick={() => setActiveTab(discussion.key)}
                            >
                                <div className='text-[13px]'> {`${discussion.name}`}</div>
                                {actionTab === discussion.key && <span></span>}
                            </li>)
                    }
                </ul>
                <div className='flex gap-2 cursor-pointer' onClick={() => navigate('/all-request?view=list-view')}>
                    <img src={ListIcon} alt="ListIcon" />
                    <p>List View</p>
                </div>
            </div>

            <div className='flex justify-end items-center'>
                <div className="relative">
                    <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                        placeholder="Search here..." style={{
                            border: '1px solid rgba(34, 96, 217, 1)',
                            height: '50px',
                            width: '300px'
                        }} />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                        <img src={SearchIcon} alt='SearchIcon' />
                    </div>
                </div>
            </div>

            <div style={{ border: '1px solid rgba(228, 237, 255, 1)', marginTop: '30px' }}>

                <div style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                    <div className='flex justify-between pt-5 px-5'>
                        <div className='flex gap-10 justify-center items-center'>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={CallIcon} alt="CallIcon" />
                            </div>
                            <h3 className='text-primary-black text-[24px]'>AID Request</h3>
                            <div style={{ border: '1px solid rgba(255, 205, 17, 1)', borderRadius: '15px' }}
                                className='bg-light-yellow text-yellow py-1 px-8'>In Progress</div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button style={{ border: '1px solid rgba(220, 53, 69, 1)' }} className='py-3 px-5 w-[200px] text-primary-red'
                                onClick={() => setDetailAction({ ...detailAction, cancelModal: true })}
                            >Cancel Request</button>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/view-request/1')}>View Request Detail</button>
                        </div>
                    </div>

                    <div className='px-40'>
                        <div className='request-info flex gap-4 text-[12px]'>
                            <p>Request ID : <span className='text-primary-blue underline'>#1234567890</span></p>
                            <p>Request Date : <span className='text-primary-blue underline'>12/2/2024</span></p>
                        </div>
                    </div>

                    <div className='stepper py-5'>

                    </div>

                </div>


                <div className='flex flex-col gap-7 pt-4 pb-9'>
                    <div className='flex gap-5 justify-between pt-5 px-5'>
                        <div className='flex gap-7 w-2/5 items-center' style={{ borderRight: '1px solid rgba(200, 219, 255, 1)' }}>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={DonationIcon} alt="CallIcon" />
                            </div>
                            <div className='flex flex-col gap-1' >
                                <h3 className='text-primary-black text-[16px]'>Donations Request</h3>
                                <div className=' text-yellow'>In Progress</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='text-[14px]'>Request ID : 1234567890</p>
                            <div className='flex items-start'>
                                <p className='text-[12px]'>This NGO truly makes a difference! Their commitment to empowering communities is evident in
                                    the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I
                                    couldn't be more impressed with their dedication and transparency.</p>
                                <img src={ArrowDownIcon} className='pt-1.5 cursor-pointer' alt="ArrowDownIcon" />
                            </div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/view-request/1')}>View Request Detail</button>
                        </div>

                    </div>

                    <div className='flex gap-5 justify-between pt-5 px-5'>
                        <div className='flex gap-7 w-2/5 items-center' style={{ borderRight: '1px solid rgba(200, 219, 255, 1)' }}>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={ChildcareIcon} alt="CallIcon" />
                            </div>
                            <div className='flex flex-col gap-1' >
                                <h3 className='text-primary-black text-[16px]'>Childcare Support</h3>
                                <div className=' text-yellow'>In Progress</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='text-[14px]'>Request ID : 1234567890</p>
                            <div className='flex items-start'>
                                <p className='text-[12px]'>This NGO truly makes a difference! Their commitment to empowering communities is evident in
                                    the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I
                                    couldn't be more impressed with their dedication and transparency.</p>
                                <img src={ArrowDownIcon} className='pt-1.5 cursor-pointer' alt="ArrowDownIcon" />
                            </div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/view-request/1')}>View Request Detail</button>
                        </div>

                    </div>

                    <div className='flex gap-5 justify-between pt-5 px-5'>
                        <div className='flex gap-7 w-2/5 items-center' style={{ borderRight: '1px solid rgba(200, 219, 255, 1)' }}>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={HousingIcon} alt="CallIcon" />
                            </div>
                            <div className='flex flex-col gap-1' >
                                <h3 className='text-primary-black text-[16px]'>Public Awareness</h3>
                                <div className=' text-yellow'>In Progress</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='text-[14px]'>Request ID : 1234567890</p>
                            <div className='flex items-start'>
                                <p className='text-[12px]'>This NGO truly makes a difference! Their commitment to empowering communities is evident in
                                    the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I
                                    couldn't be more impressed with their dedication and transparency.</p>
                                <img src={ArrowDownIcon} className='pt-1.5 cursor-pointer' alt="ArrowDownIcon" />
                            </div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/view-request/1')}>View Request Detail</button>
                        </div>

                    </div>

                    <div className='flex gap-5 justify-between pt-5 px-5'>
                        <div className='flex gap-7 w-2/5 items-center' style={{ borderRight: '1px solid rgba(200, 219, 255, 1)' }}>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={DonationIcon} alt="CallIcon" />
                            </div>
                            <div className='flex flex-col gap-1' >
                                <h3 className='text-primary-black text-[16px]'>Donations Request</h3>
                                <div className=' text-yellow'>In Progress</div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-5'>
                            <p className='text-[14px]'>Request ID : 1234567890</p>
                            <div className='flex items-start'>
                                <p className='text-[12px]'>This NGO truly makes a difference! Their commitment to empowering communities is evident in
                                    the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I
                                    couldn't be more impressed with their dedication and transparency.</p>
                                <img src={ArrowDownIcon} className='pt-1.5 cursor-pointer' alt="ArrowDownIcon" />
                            </div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/view-request/1')}>View Request Detail</button>
                        </div>

                    </div>
                </div>

            </div>

            <CancelRequest open={detailAction.cancelModal} closeModal={closeModal} />
        </div>
    );
}