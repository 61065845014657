import React from 'react'
import { useNavigate } from 'react-router'

import CallIcon from '../../../assets/request-phone-icon.svg'
import BreadCrumIcon from '../../../assets/breadcrumb-right-icon.svg'
import { Button } from '../../../shared/Button'


export default function ViewRequest() {
    const navigate = useNavigate()
    return (


        <div className="px-8 mt-10">
            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/')}>
                                Dashboard
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">View Request</span>
                            </div>
                        </li>
                    </ol>
                </nav>

            </div>
            <div style={{ border: '1px solid rgba(228, 237, 255, 1)', marginTop: '30px' }} className='pt-5 px-5'>

                <div>
                    <div className='flex justify-between'>
                        <div className='flex gap-10 justify-center items-center'>
                            <div className='bg-white py-4 px-4' style={{
                                border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '5px',
                                boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)'

                            }}>
                                <img src={CallIcon} alt="CallIcon" />
                            </div>
                            <h3 className='text-primary-black text-[24px]'>AID Request</h3>
                            <div style={{ border: '1px solid rgba(255, 205, 17, 1)', borderRadius: '15px' }}
                                className='bg-light-yellow text-yellow py-1 px-8'>In Progress</div>
                        </div>

                        <div className='flex gap-4 items-center'>
                            <button style={{ border: '1px solid rgba(220, 53, 69, 1)' }} className='py-3 px-5 w-[200px] text-primary-red'>Cancel Request</button>
                            <button className='bg-primary-blue text-white py-3 px-5 w-[200px]' onClick={() => navigate('/all-request?view=detail-view')}>Track Request</button>
                        </div>
                    </div>

                    <div className='px-40'>
                        <div className='request-info flex gap-4 text-[12px]'>
                            <p>Request ID : <span className='text-primary-blue underline'>#1234567890</span></p>
                            <p>Request Date : <span className='text-primary-blue underline'>12/2/2024</span></p>
                        </div>
                    </div>

                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='bg-light-blue text-primary-blue font-bold py-3 pl-6'>Primary Information</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>First Name</td>
                                <td>aaa</td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>aa</td>
                            </tr>
                            <tr>
                                <td>Primary Contact Number</td>
                                <td>12233</td>
                            </tr>
                            <tr>
                                <td>Secondary Contact Number</td>
                                <td>23232</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>Test</td>
                            </tr>
                        </table>
                    </div>






                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='bg-light-blue text-primary-blue font-bold py-3 pl-6'>Request Details</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>Request Via</td>
                                <td>Call</td>
                            </tr>
                            <tr>
                                <td>Request Category</td>
                                <td>Food And Nutrition's Aid</td>
                            </tr>
                            <tr>
                                <td>Number of Beneficiaries</td>
                                <td>05</td>
                            </tr>
                            <tr>
                                <td>Urgency Level</td>
                                <td>Low</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</td>
                            </tr>
                        </table>
                    </div>




                    <div style={{ border: '1px solid rgba(228, 237, 255, 1)' }} className='mt-10 mb-16'>
                        <p className='bg-light-blue text-primary-blue font-bold py-3 pl-6'>Agent Details</p>
                        <table className='w-full request-view-table'>
                            <tr>
                                <td>Name</td>
                                <td>Agent Details</td>
                            </tr>
                        </table>
                    </div>




                    <div className="flex gap-6 my-10 justify-center align-middle">
                        <Button btnName='Request Again' btnCategory="secondary" btnCls="w-[200px]" btnStyle={{border:'1px solid rgba(34, 96, 217, 1)'}} onClick={() => navigate('/')} />
                        <Button btnType="submit" id={'program-submit'} btnCls="w-[200px]" onClick={() => navigate('/all-request?view=detail-view')}
                            btnName={'Track Request'} btnCategory="primary" />
                    </div>
                </div>
            </div>
        </div>
    )
}
