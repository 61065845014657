import {
    createAsyncThunk,
} from "@reduxjs/toolkit";

import api from "./api";


// Create Request
export const createNewRequest = createAsyncThunk(
    "createNewRequest",
    async (id) => {
        const createRequest = await api.post(`/agent/`);
        if (createRequest.status === 200 && createRequest.data.result) {
            return createRequest.data.result
        }
        return createRequest
    }
);


