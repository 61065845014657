import React from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import Consumer from './Consumer'
import CommonDashboard from './CommonDashboard'

export default function Dashboard() {
    const navigate = useNavigate()
    const { userType } = useSelector(state => state.userInfo)
    return (
        <>
            {userType === 'consumer' && <Consumer />}
            {
                userType === 'other' && <CommonDashboard />
            }
        </>
    )
}
