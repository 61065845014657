import React, { useEffect, useState } from 'react';
import {
    DataGrid, gridPageCountSelector, GridPagination, gridPaginationModelSelector, useGridApiContext,
    useGridSelector, gridPageSelector, gridPageSizeSelector, gridPaginationSelector, gridPaginationMetaSelector,
} from '@mui/x-data-grid';
import { TablePagination } from "@mui/material";
import MuiPagination from '@mui/material/Pagination';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import CustomFooterPagination from './CustomFooterPagination';



export default function DataTable({ rows, columns, selectedAllRows = [],
    hideCheckbox = false, hideFooter = false, handleSelectedRow = undefined, height = 600 }) {
    const [selectedIds, setSelectedIds] = useState([])

    const handleRowSelection = (ids) => {
        const selected = [...rows].filter(row => ids.includes(row.id || row.categories_id))
        setSelectedIds(ids)
        if (handleSelectedRow) handleSelectedRow(selected)
    }

    useEffect(() => {
        const ids = []
        selectedAllRows.forEach(row => ids.push(row.id))
        setSelectedIds(ids)
    }, [])

    return (
        <div style={{ height: height, width: '100%', position: 'relative' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                hideFooterPagination={hideFooter}
                getRowId={(row) => row.id || row.first_name || row.categories_id}
                checkboxSelection={!hideCheckbox}
                onPageChange={(e) => console.log('change', e)}
                hideFooter={hideFooter}
                disableRowSelectionOnClick
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={(itm, i) => handleRowSelection(itm)}
            />
        </div>
    );
}