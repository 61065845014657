import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import DataTable from '../../shared/DataGrid'
import { PartnersTab } from '../../utills/formFields'

import BreadCrumIcon from '../../assets/breadcrumb-right-icon.svg'
import SearchIcon from '../../assets/input-search-icon.svg'
import MoreIcon from '../../assets/more-icon.svg'
import { PartnerColumn } from '../../utills/tableFields'



export default function Partner() {
    const navigate = useNavigate()

    const [actionTab, setActiveTab] = useState('all')
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState({})

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(data)
    };

    const PartnersColumn = [
        ...PartnerColumn,
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            id: 5,
            renderCell: (params) => {
                return <>
                    <div className='cursor-pointer flex items-center h-full relative'>

                        {/* <span className='w-[80px] flex justify-center h-[30px] px-7'
                            style={{
                                background: goalRequestColor[params.row.status].bg, lineHeight: '30px',
                                borderRadius: '3px', width: '110px', height: '34px', color: goalRequestColor[params.row.status].color
                            }}>
                            {goalRequestStatus[params.row.status]}
                        </span> */}
                    </div>
                </>
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            id: 4,
            renderCell: (params) => {
                return <>
                    <div className='cursor-pointer flex items-center h-full' onClick={(e) => handleClick(e, params.row)}>
                        <img src={MoreIcon} alt='MoreIcon' />
                    </div>
                    {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => navigate(`/${selectedMentee.id}`)} className='!text-[12px]'>
                            <img src={ViewIcon} alt="ViewIcon" className='pr-3 w-[30px]' />
                            View
                        </MenuItem>
                    </Menu> */}
                </>
            }
        },
    ]

    return (
        <div className="px-8 mt-10">
            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                            >
                                Partner
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">Partner Status</span>
                            </div>
                        </li>
                    </ol>
                </nav>

            </div>

            <div className='flex justify-between pb-4'>
                <div>Partner</div>
            </div>


            <div className='px-3 py-5 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                <div className='details-info'>
                    {
                        <div className='flex justify-between px-5 mb-8 items-center ' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                            <ul className='tab-list'>
                                {
                                    PartnersTab.map((discussion, index) =>
                                        <li className={`${actionTab === discussion.key ? 'active' : ''} relative`} key={index}
                                            onClick={() => setActiveTab(discussion.key)}
                                        >
                                            <div className='flex justify-center pb-1'>
                                                <div className={`total-proram-count relative ${actionTab === discussion.key ? 'active' : ''}`}>10
                                                </div>
                                            </div>
                                            <div className='text-[13px]'> {`${discussion.name}`}</div>
                                            {actionTab === discussion.key && <span></span>}
                                        </li>)
                                }
                            </ul>

                            <div className="relative">
                                <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                    placeholder="Search here..." style={{
                                        border: '1px solid rgba(34, 96, 217, 1)',
                                        height: '50px',
                                        width: '390px'
                                    }} />
                                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                                    <img src={SearchIcon} alt='SearchIcon' />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <DataTable rows={[]} columns={PartnersColumn} hideCheckbox />
            </div>
        </div >
    )
}
