import React from 'react'
import DataTable from '../../shared/DataGrid'
import { CurrentStatusColumn, FrequentRequestColumn } from '../../utills/tableFields'

import SearchIcon from '../../assets/input-search-icon.svg'

export default function CommonDashboard() {
    return (
        <div className='px-4 lg:px-6 py-2.5'>
            <div className='flex gap-9 my-4 items-center'>
                <p className='text-primary-blue text-[22px] font-semibold'>Good Morning, John</p>
                <p className='flex gap-3'>
                    <span style={{ borderRight: '1px solid rgba(0, 0, 0, 1)', paddingRight: '10px' }}>09.29 AM </span>
                    <span>09-08-2024 </span>
                </p>
            </div>

            <div>
                <div className="grid grid-cols-7 gap-5 py-5">
                    <div className="col-span-3">
                        <div className='px-2 py-3 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                            <div className='grid grid-cols-2 gap-2'>
                                <div style={{ border: '1px solid rgba(28, 101, 243, 1)', background: 'rgba(236, 242, 255, 1)', height: '200px' }}>
                                    <div className='flex flex-col gap-3 items-start justify-end h-full px-3 py-4'>
                                        <p className='text-[36px]'>258</p>
                                        <p>Total Request</p>
                                    </div>

                                </div>
                                <div style={{ border: '1px solid rgba(23, 144, 11, 1)', background: 'rgba(241, 255, 239, 1)', height: '200px' }}>
                                    <div className='flex flex-col gap-3 items-start justify-end h-full px-3 py-4'>
                                        <p className='text-[36px]'>258</p>
                                        <p>Online Request</p>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid rgba(255, 213, 0, 1)', background: 'rgba(254, 255, 217, 1)', height: '200px' }}>
                                    <div className='flex flex-col gap-3 items-start justify-end h-full px-3 py-4'>
                                        <p className='text-[36px]'>258</p>
                                        <p>Call Request</p>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid rgba(255, 123, 0, 1)', background: 'rgba(255, 245, 231, 1)', height: '200px' }}>
                                    <div className='flex flex-col gap-3 items-start justify-end h-full px-3 py-4'>
                                        <p className='text-[36px]'>258</p>
                                        <p>Walk-In Request</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className='px-6 py-2 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                            <p className='px-3 py-3 text-[20px] mb-3' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>Frequently Requested Categories</p>

                            <DataTable rows={[]} columns={FrequentRequestColumn} hideCheckbox height={350} />
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <div className='px-6 py-2 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                    <div className='flex justify-between items-center mb-6 py-3' style={{ borderBottom: '1px solid rgba(211, 211, 211, 1)' }}>
                        <p className='px-1 py-1 text-[20px] mb-3' >Current Request Status</p>

                        <div className='flex gap-8 items-center'>
                            <div className="relative">
                                <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                    placeholder="Search here..." style={{
                                        border: '1px solid rgba(34, 96, 217, 1)',
                                        height: '50px',
                                        width: '300px'
                                    }} />
                                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                                    <img src={SearchIcon} alt='SearchIcon' />
                                </div>
                            </div>
                            <div style={{
                                background: 'rgba(210, 228, 255, 1)', color: 'rgba(34, 96, 217, 1)', borderRadius: '3px',
                                width: '100px'
                            }}
                                className='py-3 px-3 text-center cursor-pointer'
                            >
                                View all
                            </div>
                        </div>

                    </div>
                    <DataTable rows={[]} columns={CurrentStatusColumn} hideCheckbox height={350} />

                </div>
            </div>

        </div>
    )
}
