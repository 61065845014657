import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { LoginSocialGoogle } from "reactjs-social-login";
import { useForm } from 'react-hook-form';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import MuiModal from "./shared/Modal";
import MyProfile from './components/MyProfile';
import { loginSocialMedia, updateUserInfo } from './services/user';
import { userApiResponse } from './constants/apiResponse';
import { Button } from './shared/Button';

import GoogleIcon from './assets/google-icon.svg'
import FacebookIcon from './assets/facebook-icon.svg'
import InstagramIcon from './assets/instagram-icon.svg'
import PlusIcon from './assets/plus-white-circle.svg'
import EmailIcon from './assets/email-icon.svg'
import NotificationIcon from './assets/notification-bell-icon.svg'
import UserImage from './assets/images/user.png'
import HelpIcon from './assets/help-icon.svg';
import ProfileIcon from './assets/profile-icon.svg';
import LogoutIcon from './assets/logout-icon.svg';
import LogoutColorIcon from './assets/logout-popup-icon.svg'




export default function Navbar() {
    const navigate = useNavigate()
    const { data, status, userType } = useSelector(state => state.userInfo)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const [loginModal, setLoginModal] = useState()
    const [isLogout, setIsLogout] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [userAction, setUserAction] = useState({ profileModal: false })
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()

    const handleCloseLoginModal = () => {
        reset()
        setLoginModal(false)
    }

    const onLoginStart = useCallback(() => {
        // alert("login start");
    }, []);

    const onLoginSuccess = (data) => {
        console.log('Google login', data)

        if (data && Object.keys(data).length && data.hasOwnProperty('name') && data.hasOwnProperty('email')) {
            let l = { email: data.email }
            console.log('Login', l)
            dispatch(loginSocialMedia(l))
        }
    }

    const onLogoutFailure = useCallback(() => {
        alert("logout fail");
    }, []);

    const onLogoutSuccess = useCallback(() => {
        alert("logout success");
    }, []);

    const handleCloseUserProfile = () => {
        setUserAction({ ...userAction, profileModal: false })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        dispatch({ type: "logout" })
        navigate("/");
        setIsLogout(false)
    }


    const onSubmit = (data) => {
        console.log('Submit')
    }

    useEffect(() => {
        if (status === userApiResponse.loggin) {
            handleCloseLoginModal()
            dispatch(updateUserInfo({ status: '' }))
        }
    }, [status])

    return (
        <>
            <header className="bg-white py-3" style={{ borderBottom: '1px solid #353F4F' }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLogout}
                >
                    <div className="popup-content w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
                        <img src={LogoutColorIcon} alt="LogoutColorIcon" />
                        <span style={{ color: '#232323', fontWeight: 600, fontSize: '24px' }}>Log out</span>

                        <div className='py-5'>
                            <p style={{ color: 'rgba(24, 40, 61, 1)', fontWeight: 600, fontSize: '18px' }}>Are you sure you want to log out ?</p>
                        </div>
                        <div className='flex justify-center'>
                            <div className="flex gap-6 justify-center align-middle">
                                <Button btnName='Cancel' btnStyle={{color: '#000'}} btnCategory="secondary" onClick={() => setIsLogout(false)} />
                                <Button btnType="button" btnCls="w-[110px]" btnName={'Logout'} btnCategory="primary"
                                    onClick={handleLogout}
                                />
                            </div>
                        </div>
                    </div>

                </Backdrop>

                <nav className="border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
                    <div className="flex flex-wrap justify-between items-center">
                        <div className="flex items-center gap-44">
                            <span className="self-center font-semibold whitespace-nowrap text-[28px] cursor-pointer text-primary-blue" onClick={() => navigate('/')}>CRRMS</span>

                            <div className="justify-between items-center w-full lg:flex md-flex lg:w-auto lg:order-1" id="mobile-menu-2">
                                <ul className="flex items-center mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 primary-black">
                                    {
                                        userType === 'consumer' &&
                                        <>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/')}>Home</span>
                                            </li>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/category')}>Categories</span>
                                            </li>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/all-request')}>My Requests</span>
                                            </li>
                                        </>
                                    }

                                    {
                                        userType === 'other' &&
                                        <>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/')}>Dashboard</span>
                                            </li>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/partners')}>Partner</span>
                                            </li>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/category')}>Categories</span>
                                            </li>
                                            <li>
                                                <span className="block py-2 pr-4 pl-3 primary-black font-normal cursor-pointer" onClick={() => navigate('/all-request')}>Requests</span>
                                            </li>
                                        </>
                                    }


                                </ul>
                            </div>
                        </div>
                        {
                            Object.keys(data).length === 0 &&
                            <div className="flex items-center lg:order-2">
                                <span className="text-white py-3 px-16 bg-primary-blue font-normal rounded cursor-pointer"
                                    onClick={() => setLoginModal(true)}
                                >Login/Signup</span>
                            </div>
                        }

                        {
                            Object.keys(data).length > 0 &&


                            <div className='flex gap-10 items-center'>
                                {/* <button className='bg-primary-orange text-white px-10 py-3 rounded flex gap-3 cursor-pointer'
                                    onClick={() => navigate('/create-request')}
                                >
                                    <img src={PlusIcon} alt='PlusIcon' />
                                    <span>Take New Request</span>
                                </button> */}
                                <img src={NotificationIcon} alt="NotificationIcon" />
                                <img className='rounded-3xl object-cover h-8 w-8 cursor-pointer' src={UserImage} alt="User Icon"
                                    onClick={handleClick} />

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        navigate('/help');
                                    }}>
                                        <img src={HelpIcon} alt="HelpIcon" className='pr-3 w-[30px]' />
                                        Help</MenuItem>

                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setUserAction({ ...userAction, profileModal: true });
                                    }}>
                                        <img src={ProfileIcon} alt="ProfileIcon" className='pr-3 w-[30px]' />
                                        Profile</MenuItem>


                                    <MenuItem onClick={() => { handleClose(); setIsLogout(true) }}>
                                        <img src={LogoutIcon} alt="LogoutIcon" className='pr-3 w-[30px]' />
                                        Log out</MenuItem>
                                </Menu>
                            </div>
                        }

                    </div>
                </nav>
            </header>

            <MuiModal modalSize="md" modalOpen={loginModal} modalClose={handleCloseLoginModal} noheader>
                <div className='flex flex-col items-center py-7'>
                    <h5 className='text-primary-blue text-[26px] text-center font-medium'>CRRMS</h5>
                    <p className='text-primary-black text-[18px] py-4'>Welcome to the Request Management System</p>
                    <p className='text-primary-black text-[16px] pb-6'>Login for a seamless experience</p>
                    <div className='flex gap-4'>
                        <LoginSocialGoogle
                            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            onLogoutFailure={onLogoutFailure}
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            scope="openid profile email"
                            onResolve={(e) => {
                                onLoginSuccess(e.data)
                            }}
                            onReject={(err) => {
                                console.log("hbhbdhd", err);
                            }}
                        >
                            <div className="cursor-pointer px-6 py-3" style={{ border: '0.5px solid rgba(34, 96, 217, 0.5)', borderRadius: '3px' }}>
                                <img src={GoogleIcon} alt='GoogleIcon' />
                            </div>
                        </LoginSocialGoogle>
                        <div className="cursor-pointer px-6 py-3" style={{ border: '0.5px solid rgba(34, 96, 217, 0.5)', borderRadius: '3px' }}>
                            <img src={InstagramIcon} alt='InstagramIcon' />
                        </div>
                        <div className="cursor-pointer px-6 py-3" style={{ border: '0.5px solid rgba(34, 96, 217, 0.5)', borderRadius: '3px' }}>
                            <img src={FacebookIcon} alt='FacebookIcon' />
                        </div>
                        <div className="cursor-pointer px-6 py-3" style={{ border: '0.5px solid rgba(34, 96, 217, 0.5)', borderRadius: '3px' }}>
                            <img src={EmailIcon} alt='EmailIcon' />
                        </div>
                    </div>
                    <div className="mb-8 mt-8 flex items-center before:mt-0.5 before:flex-1 before:border-t
                     before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t
                      after:border-neutral-300 dark:before:border-neutral-500
                       dark:after:border-neutral-500" style={{ color: 'rgba(183, 185, 189, 1)', width: '50%' }}>
                        <p
                            className="mx-4 mb-0 text-center font-semibold dark:text-neutral-200"
                            style={{
                                color: 'rgba(183, 185, 189, 1)',
                                border: '1px solid rgba(183, 185, 189, 1)',
                                padding: '2px 20px',
                                borderRadius: '20px',
                                fontSize: '14px'
                            }}
                        >
                            OR
                        </p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className='w-[50%]'>
                        <div className="flex flex-wrap gap-2">
                            <div className="relative mb-6 w-full">
                                <input
                                    type={'number'}
                                    className={`w-full rounded px-3 py-[0.32rem] text-[14px] leading-[2.15] h-[60px] ${errors['mobile_number'] ? 'focus:border-teal focus:outline-none focus:ring-0' : ''}`}
                                    placeholder={'Enter Mobile Number'}
                                    style={{
                                        color: "#232323",
                                        border: `1px solid ${errors['mobile_number'] ? 'rgb(239 68 68)' : 'rgba(34, 96, 217, 1)'}`,
                                    }}
                                    {...register('mobile_number',
                                        {
                                            required: "This field is required",
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: 'Please Enter Valid Mobile Number'
                                            },
                                        }
                                    )}
                                    aria-invalid={errors['mobile_number'] ? "true" : "false"}
                                />
                                {errors['mobile_number'] && (
                                    <p className="error" role="alert">
                                        {errors['mobile_number'].message}
                                    </p>
                                )}
                            </div>

                            <div className="relative w-full">
                                <button
                                    type="submit"
                                    className="inline-block w-full rounded px-7 py-4 text-sm font-medium bg-primary-blue text-white "
                                    data-twe-ripple-init
                                    data-twe-ripple-color="light"
                                >
                                    Login with OTP
                                </button>


                            </div>

                        </div>
                    </form>
                </div>
            </MuiModal>

            <MyProfile open={userAction.profileModal} closeModal={handleCloseUserProfile} />

        </>
    )
}
