import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'

import BreadCrumIcon from '../../assets/breadcrumb-right-icon.svg'
import ProfileIcon from '../../assets/user-profile-icon.svg'
import ShieldIcon from '../../assets/security-shield-icon.svg'
import LockIcon from '../../assets/lock-icon.svg'
import ProfileEditIcon from '../../assets/profile-edit-icon.svg'
import ProfileImageIcon from '../../assets/profile-image-icon.svg'
import ProfileImagePencilIcon from '../../assets/profile-image-pencil-icon.svg'
import EyeOpenIcon from '../../assets/eye-open-icon.svg'
import PermissionCheckIcon from '../../assets/permission-check-icon.svg'
import EyeCloseIcon from '../../assets/images/eye-close-icon.png'




export default function Profile() {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('profile')

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        getFieldState,
        getValues,
        setError,
        setValue
    } = useForm();

    const onSubmit = (data) => {
        console.log('Change password', data)
    }

    return (
        <div className="px-8 mt-10">
            <div className='mb-6'>
                <nav class="flex" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <span class="inline-flex items-center text-sm text-gray-700 hover:text-blue-600 dark:text-gray-400 cursor-pointer dark:hover:text-white"
                                onClick={() => navigate('/')}>
                                Dashboard
                            </span>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <img src={BreadCrumIcon} alt="BreadCrumIcon" />
                                <span class="ms-1 text-sm  md:ms-2 text-primary-blue">View Profile</span>
                            </div>
                        </li>


                    </ol>
                </nav>
            </div>

            <p className='pb-3'>Settings</p>

            <div style={{ border: '1px solid rgba(34, 96, 217, 1)', borderRadius: '3px', marginBottom: '40px' }}>
                <div className='pt-4 grid grid-cols-7'>
                    <div className='col-span-2 left-side-bar' style={{ borderRight: '1px solid rgba(217, 217, 217, 1)' }}>
                        <div className={`cursor-pointer ${activeTab === 'profile' ? 'active' : ''}`} onClick={() => setActiveTab('profile')}>
                            <div className='flex gap-3 py-4 px-5'>
                                <img src={ProfileIcon} alt="ProfileIcon" />
                                <p>Profile</p>
                            </div>
                        </div>
                        <div className={`cursor-pointer ${activeTab === 'security' ? 'active' : ''}`} onClick={() => setActiveTab('security')}>
                            <div className='flex gap-3 py-4 px-5'>
                                <img src={ShieldIcon} alt="ShieldIcon" />
                                <p>Security</p>
                            </div>
                        </div>
                        <div className={`cursor-pointer ${activeTab === 'permission' ? 'active' : ''}`} onClick={() => setActiveTab('permission')}>
                            <div className='flex gap-3 py-4 px-5'>
                                <img src={LockIcon} alt="LockIcon" />
                                <p>Permission</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-5 px-9 pb-9 '>
                        {
                            activeTab === 'profile' &&
                            <>
                                <div className='flex justify-between items-center '>
                                    <p>Profile Picture</p>
                                    <button className='flex gap-2 items-center text-[14px] w-[100px] py-3 justify-center' style={{ border: '1px solid rgba(53, 63, 79, 1)', borderRadius: '3px' }}>
                                        <img src={ProfileEditIcon} alt="ProfileEditIcon" />
                                        Edit
                                    </button>
                                </div>

                                <div className='py-4 relative'>
                                    <img src={ProfileImageIcon} alt="ProfileImageIcon" />
                                    <img src={ProfileImagePencilIcon} className='absolute top-[50%] left-2 cursor-pointer' alt="ProfileImagePencilIcon" />
                                </div>

                                <div className='grid grid-cols-3 text-[13px] py-6'>
                                    <div className='pb-4'>
                                        <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Company Name</p>
                                        <p style={{ color: 'rgba(53, 63, 79, 1)' }}>Test</p>
                                    </div>
                                    <div className='pb-4'>
                                        <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Contact Number</p>
                                        <p style={{ color: 'rgba(53, 63, 79, 1)' }}>1233</p>
                                    </div>
                                    <div className='pb-4'>
                                        <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Mail ID</p>
                                        <p style={{ color: 'rgba(53, 63, 79, 1)' }}>kevinrobinson@sample.come</p>
                                    </div>


                                    <div>
                                        <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Agent  ID</p>
                                        <p style={{ color: 'rgba(53, 63, 79, 1)' }}>1233</p>
                                    </div>
                                    <div>
                                        <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Address</p>
                                        <p style={{ color: 'rgba(53, 63, 79, 1)' }}>John Doe, 1234 Maple Street, Apartment 5B, Springfield, IL 62704, United States</p>
                                    </div>
                                    <div></div>

                                </div>
                                <div className='text-[13px] pb-4'>
                                    <p style={{ color: 'rgba(116, 116, 116, 1)' }}>Professional Bio</p>
                                    <p style={{ color: 'rgba(53, 63, 79, 1)' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  elit, sed doaliqua. Ut enim ad minim veniam,..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  elit, sed doaliqua. Ut enim ad minim veniam,..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  elit, sed doaliqua. Ut enim ad minim veniam,..Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed  elit, sed doaliqua. Ut enim ad minim veniam,..</p>
                                </div>
                            </>
                        }

                        {
                            activeTab === 'security' &&
                            <>
                                <div className='flex justify-between items-center '>
                                    <p>Password</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='py-7'>
                                        <div className="relative mb-6">
                                            <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                                                Current Password
                                            </label>

                                            <div className='relative'>
                                                <input {...register('current-password', { required: 'This field is required' })}
                                                    type="password"
                                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                                    placeholder={'Current Password'}
                                                    style={{
                                                        color: "#232323",
                                                        borderRadius: '3px'
                                                    }}
                                                    aria-invalid={!!errors['current-password']}
                                                />
                                                <img className='absolute top-4 right-4 w-[25px]' src={EyeCloseIcon} alt="EyeCloseIcon" />
                                                {errors['current-password'] && (
                                                    <p className="error" role="alert">
                                                        {errors['current-password'].message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="relative mb-6">
                                            <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                                                New Password
                                            </label>

                                            <div className='relative'>
                                                <input {...register('new-password', { required: 'This field is required' })}
                                                    type="password"
                                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                                    placeholder={'New Password'}
                                                    style={{
                                                        color: "#232323",
                                                        borderRadius: '3px'
                                                    }}
                                                    aria-invalid={!!errors['new-password']}
                                                />
                                                <img className='absolute top-4 right-4 w-[25px]' src={EyeCloseIcon} alt="EyeCloseIcon" />
                                                {errors['new-password'] && (
                                                    <p className="error" role="alert">
                                                        {errors['new-password'].message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="relative mb-6">
                                            <label className="block tracking-wide text-gray-700 text-xs font-normal mb-2">
                                                Confirm Password
                                            </label>

                                            <div className='relative'>
                                                <input {...register('confirm-password', { required: 'This field is required' })}
                                                    type="password"
                                                    className="w-full border-none px-3 py-[0.32rem] leading-[2.15] input-bg focus:border-none focus-visible:border-none 
                                                            focus-visible:outline-none text-[14px] h-[60px]"
                                                    placeholder={'Confirm Password'}
                                                    style={{
                                                        color: "#232323",
                                                        borderRadius: '3px'
                                                    }}
                                                    aria-invalid={!!errors['confirm-password']}
                                                />
                                                <img className='absolute top-4 right-4 w-[25px]' src={EyeCloseIcon} alt="EyeCloseIcon" />
                                                {errors['confirm-password'] && (
                                                    <p className="error" role="alert">
                                                        {errors['confirm-password'].message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="relative mb-6 flex justify-end mt-10">
                                            <button className='bg-primary-blue text-white py-4 px-4 w-[200px]' style={{ borderRadius: '3px' }}>Reset Password</button>
                                        </div>
                                    </div>

                                </form>

                            </>
                        }

                        {
                            activeTab === 'permission' &&
                            <div className='py-7'>
                                <table className='permission-table text-[14px]'>
                                    <thead>
                                        <tr>
                                            <th>Name of Permissions</th>
                                            <th>Create</th>
                                            <th>View</th>
                                            <th>Edit</th>
                                            <th>Accept</th>
                                            <th>Reject</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Request</td>
                                            <td><img src={PermissionCheckIcon} alt="PermissionCheckIcon" /></td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Partnet</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Category</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Profile</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Password</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                            <td>N/A</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
