import React from 'react'
import { useNavigate } from 'react-router'

import FrequentCategory from '../FrequentCategory'
import TopCategory from '../TopCategory'
import PopularSearch from '../PopularSearch'

import PlusIcon from '../../assets/plus-white-circle.svg'
import SearchIcon from '../../assets/search-icon.svg'
import LocationIcon from '../../assets/location-icon.svg'



export default function Consumer() {
    const navigate = useNavigate()
    return (
        <div className='px-4 lg:px-6 py-2.5'>
            <div className='flex gap-9 my-4'>
                <div className="relative">
                    <div className="absolute left-2 top-4">
                        <img src={LocationIcon} className='h-5' alt='SearchIcon' />
                    </div>
                    <input type='text' className='block w-full p-2 text-sm text-gray-900 bg-gray-bg' placeholder='California'
                        style={{
                            border: '2px solid #F0F0F0',
                            borderRadius: '3px',
                            height: '58px',
                            width: '350px',
                            paddingLeft: '30px'
                        }} />
                </div>
                <div className="relative">
                    <input type="text" className="block w-full p-2 text-sm text-gray-900 border-none"
                        placeholder="Search here..." style={{
                            border: '1px solid rgba(29, 91, 191, 1)',
                            borderRadius: '3px',
                            height: '58px',
                            width: '350px'
                        }}
                        onChange={(e) => console.log(e)}
                    />
                    <div className="absolute right-2 top-3">
                        <img src={SearchIcon} alt='SearchIcon' />
                    </div>
                </div>
                <button className='bg-primary-orange text-white px-16 py-4 rounded flex gap-3 cursor-pointer'
                    onClick={() => navigate('/create-request')}
                >
                    <img src={PlusIcon} alt='PlusIcon' />
                    <span>Create New Request</span>
                </button>
            </div>

            <div className='text-primary-blue py-3 text-[28px] font-bold'>
                Welcome to Community Request and Referral Management System !
            </div>

            <FrequentCategory />
            <TopCategory />
            <PopularSearch />
        </div>
    )
}
