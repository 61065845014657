import {
    createSlice
} from "@reduxjs/toolkit";



const initialState = {
    data: [],
    details: {},
    loading: false,
    status: '',
    error: "",
};

export const partnerSlice = createSlice({
    name: "partnetInfo",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {}
});


export default partnerSlice.reducer;