import React, { useState } from 'react'
import { Backdrop } from '@mui/material';

import { Button } from '../../shared/Button';

import SearchIcon from '../../assets/input-search-icon.svg'
import CalenderIcon from "../../assets/calendar-icon.svg";
import housingIcon from '../../assets/housing-repair-icon.svg'
import childcareIcon from '../../assets/childcare-icon.svg'
import counselingIcon from '../../assets/counseling-icon.svg'
import CloseRedIcon from '../../assets/close-light-red-icon.svg'
import CloseRedBgIcon from '../../assets/close-red-icon.svg'



export default function Notification() {
    const [notificationAction, setNotificationIcon] = useState({ modal: false, selectedItem: {} })

    const handleClearBtn = (item) => {
        setNotificationIcon({ modal: true, selectedItem: item })
    }

    const handleClosePopup = () => {
        setNotificationIcon({ modal: false, selectedItem: {} })
    }

    const handleConfirmPopup = () => {
        handleClosePopup()
    }

    const PopularSearchItem = [
        {
            name: 'AID Request Accepted',
            time: '15m ago',
            msg: "This NGO truly makes a difference! Their commitment to empowering communities is evident in the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I couldn't be more impressed with their dedication and transparency.",
            img: housingIcon
        },
        {
            name: 'Charitable Fundraising',
            time: '15m ago',
            msg: "This NGO truly makes a difference! Their commitment to empowering communities is evident in the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I couldn't be more impressed with their dedication and transparency.",
            img: childcareIcon
        },
        {
            name: 'Event Planning',
            time: '15m ago',
            msg: "This NGO truly makes a difference! Their commitment to empowering communities is evident in the tangible results they deliver. I've seen firsthand how their projects have transformed lives, and I couldn't be more impressed with their dedication and transparency.",
            img: counselingIcon
        }

    ]

    return (
        <div className=" px-8 mt-10">
            <div className='px-3 py-5 mb-5' style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.15)', borderRadius: '5px' }}>
                <div className='flex justify-between px-5 pb-4 mb-8 items-center'>
                    <div className='flex gap-5 items-center '>
                        <p className='text-[24px]'>All Notification</p>
                    </div>
                    <div className='flex gap-8 items-center'>
                        <div className="relative">
                            <input type="text" id="search-navbar" className="block w-full p-2 text-sm text-gray-900 border-none"
                                placeholder="Search here..." style={{
                                    border: '1px solid rgba(34, 96, 217, 1)',
                                    height: '50px',
                                    width: '300px'
                                }} />
                            <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                                <img src={SearchIcon} alt='SearchIcon' />
                            </div>
                        </div>

                        <div
                            className="relative flex gap-3 py-3 px-3"
                            style={{
                                border: "1px solid rgba(34, 96, 217, 0.25)",
                                borderRadius: "3px",
                            }}
                        >
                            <img src={CalenderIcon} alt="CalenderIcon" />
                            <select
                                className="focus:outline-none"
                                onChange={() => undefined}
                            >
                                <option value={"month"}>Month</option>
                                <option value={"year"}>Year</option>
                            </select>
                        </div>

                    </div>
                </div>

                <div className='notification-list px-24'>
                    <div className='flex gap-6 items-center'>
                        <p>Today</p>
                        <p className='bg-secondary-blue text-primary-blue py-1 px-1'>03</p>
                    </div>

                    <div className='flex flex-col  gap-10 py-7'>
                        {
                            PopularSearchItem.map((item, index) => {
                                return (
                                    <div className='flex gap-10' key={index}>
                                        <div style={{ boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.1)', borderRadius: '3px' }} className='flex py-7 px-8 items-center'>
                                            <div className='flex gap-4 w-[75%]'>
                                                <img src={item.img} alt="EventPlanningIcon" />
                                                <div className='flex flex-col'>
                                                    <h5 className=' text-[14px]'>{item.name}</h5>
                                                    <p className='text-primary-black text-[14px] pt-3'>{item.msg}</p>
                                                </div>
                                            </div>

                                            <div className='flex justify-end w-[25%]'>
                                                <button className='bg-primary-blue text-white py-2 cursor-pointer w-[30%]' style={{ borderRadius: '3px' }}>View</button>
                                            </div>

                                        </div>
                                        <img src={CloseRedIcon} className='cursor-pointer' alt="CloseRedIcon" onClick={() => handleClearBtn(item)} />
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='flex gap-6 items-center'>
                        <p>Yesterday</p>
                        <p className='bg-secondary-blue text-primary-blue py-1 px-1'>03</p>
                    </div>


                    <div className='flex flex-col  gap-10 py-7'>
                        {
                            PopularSearchItem.map((item, index) => {
                                return (
                                    <div className='flex gap-10' key={index}>
                                        <div style={{ boxShadow: '4px 4px 15px 0px rgba(0, 0, 0, 0.1)', borderRadius: '3px' }} className='flex  py-7 px-8 items-center'>
                                            <div className='flex gap-4 w-[75%]'>
                                                <img src={item.img} alt="EventPlanningIcon" />
                                                <div className='flex flex-col'>
                                                    <h5 className=' text-[14px]'>{item.name}</h5>
                                                    <p className='text-primary-black text-[14px] pt-3'>{item.msg}</p>
                                                </div>
                                            </div>

                                            <div className='flex justify-end w-[25%]'>
                                                <button className='bg-primary-blue text-white py-2 cursor-pointer w-[30%]' style={{ borderRadius: '3px' }}>View</button>
                                            </div>

                                        </div>
                                        <img src={CloseRedIcon} className='cursor-pointer' alt="CloseRedIcon" onClick={() => handleClearBtn(item)} />
                                    </div>
                                )
                            })
                        }
                    </div>



                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 1 }}
                        open={notificationAction.modal}
                    >
                        <div className="popup-content relative w-2/6 bg-white flex flex-col gap-2 h-[330px] justify-center items-center">
                            <img src={CloseRedBgIcon} className='absolute top-1 right-1' alt="TickColorIcon" />

                            <div className='py-5'>
                                <p style={{ color: 'rgba(24, 40, 61, 1)', fontWeight: 600, fontSize: '18px' }}>
                                    Are you sure want to clear the notification??
                                </p>
                            </div>
                            <div className='flex justify-center'>
                                <div className="flex gap-6 justify-center align-middle">
                                    <Button btnCls="w-[110px] text-primary-black" btnName={'No'}  btnCategory="secondary" onClick={handleClosePopup} />
                                    <Button btnType="button" btnCls="w-[110px]" btnName={'Yes'}
                                        style={{ background: '#E0382D' }} btnCategory="primary"
                                        onClick={handleConfirmPopup}
                                    />
                                </div>
                            </div>
                        </div>

                    </Backdrop>

                </div>

            </div>
        </div>
    )
}
