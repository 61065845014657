import React from 'react'
import EventPlanningIcon from '../../assets/event-planning.svg'
import CharitableIcon from '../../assets/charitable-icon.svg'
import EventSponsorIcon from '../../assets/event-sponsorship.svg'


export default function PopularSearch() {
    const PopularSearchItem = [
        {
            name: 'Event Planning',
            img: EventPlanningIcon
        },
        {
            name: 'Charitable Fundraising',
            img: CharitableIcon
        },
        {
            name: 'Event Planning',
            img: EventSponsorIcon
        }

    ]
    return (
        <div style={{ boxShadow: '4px 4px 25px 0px rgba(0, 0, 0, 0.05)', borderRadius: '10px' }} className='mt-5'>
            <div className="title flex justify-between py-3 px-4 items-center" style={{ borderBottom: '1px solid #2260D9' }}>
                <div className="flex gap-4 items-center w-full justify-between">
                    <h4 className='text-[20px]'>Popular Search</h4>

                    <div style={{ background: 'rgba(210, 228, 255, 1)', color: 'rgba(34, 96, 217, 1)', borderRadius: '3px' }}
                        className='py-1 px-3 cursor-pointer'
                    >
                        View all
                    </div>
                </div>
            </div>
            <div className='flex flex-col px-4 gap-10 py-7'>
                {
                    PopularSearchItem.map((item, index) => {
                        return (
                            <div key={index} style={{ border: '1px solid rgba(34, 96, 217, 1)', borderRadius: '3px' }} className='flex bg-light-blue py-4 px-8 items-center'>
                                <div className='flex gap-4 w-[75%]'>
                                    <img src={item.img} alt="EventPlanningIcon" />
                                    <div className='flex flex-col'>
                                        <h5 className='text-primary-blue text-[18px] font-medium'>{item.name}</h5>
                                        <p className='text-primary-black text-[14px] pt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s,galley of type and scrambled it to make a type specimen book. It has survived not
                                            on</p>
                                    </div>
                                </div>

                                <div className='flex justify-end w-[25%]'>
                                    <button className='bg-primary-blue text-white py-3 cursor-pointer w-[50%]' style={{ borderRadius: '3px' }}>View Details</button>
                                </div>

                            </div>
                        )
                    })
                }


            </div>
        </div>
    )
}
