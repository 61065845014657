function Switch({ value, onChange }) {
  return (
    <div
      onClick={() => onChange(!value)}
      className="border-2 border-primary-blue text-primary-blue font-medium text-xl rounded-3xl px-3 py-2 flex items-center justify-between w-24 cursor-pointer"
    >
      {value && "On"}
      <span className="w-6 h-6 rounded-full bg-primary-blue"></span>
      {!value && "Off"}
    </div>
  );
}

export default Switch;
